import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  creator: null,
};

export const userCreatorSlice = createSlice({
  name: "userCreator",
  initialState,
  reducers: {
    setUserCreatorData: (state, action) => {
      state.creator = action.payload;
    },
    updateUserCreatorField: (state, action) => {
      const { field, value } = action.payload;
      if (state.creator) {
        state.creator[field] = value;
      }
    },
    clearCreatorData: (state, action) => {
      state.creator = action.payload;
    },
    deleteNetwork: (state, action) => {
      const { field, name } = action.payload;
      state.creator[field] = state.creator[field].filter(
        (network) => network.name !== name
      );
    },
    updateNetworksField: (state, action) => {
      if (state.creator && Array.isArray(state.creator.networks)) {
        state.creator.networks.push(action.payload);
      } else if (state.creator) {
        state.creator.networks = [action.payload];
      }
    },
  },
});

export const {
  setUserCreatorData,
  updateUserCreatorField,
  clearCreatorData,
  deleteNetwork,
  updateNetworksField,
} = userCreatorSlice.actions;

export default userCreatorSlice.reducer;
