import React, { useEffect, useRef, useState } from "react";
import avatar from "../../../assets/images/avatarDemo.png";
import youtube from "../../../assets/icons/IconYoutube.svg";
import tiktok from "../../../assets/icons/IconTiktok.svg";
import instagram from "../../../assets/icons/IconInstagram.svg";
import plus from "../../../assets/icons/IconPlus.svg";
import noData from "../../../assets/images/undraw-no-data.svg";
import group from "../../../assets/icons/IconGroup.svg";
import iconDelete from "../../../assets/icons/IconDelete.svg";
import "../styles/ProfileNetworks.styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useAddNetworkMutation } from "../../../redux/api/network/networkQuery";
import { PLATFORMS } from "../../../redux/constants";
import {
  setModalAddNetwork,
  setPlatform,
} from "../../../redux/api/network/networkSlice";
import {
  setModalDeleteNet,
  setModalErrorNetwork,
  setModalSuccessNetwork,
} from "../../../redux/modalSlice";
import { updateNetworksField } from "../../../redux/api/user/userCreatorSlice";

const networks = [
  { platform: "YOUTUBE", icon: youtube, title: "Youtube" },
  { platform: "TIKTOK", icon: tiktok, title: "Tiktok" },
  { platform: "INSTAGRAM", icon: instagram, title: "Instagram" },
];

const ProfileNetworksCreator = ({ userType }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("YOUTUBE");
  const { t } = useTranslation();
  const userLoggedIn = useSelector((state) => state.login.user);
  const loggedInType = userLoggedIn?.type?.toLowerCase() || "";
  const creatorRedux = useSelector((state) => state.userCreator.creator);

  const [
    addNetwork,
    {
      data: networkData,
      isSuccess: isNetworkSuccess,
      isError: isNetworkError,
      error: networkError,
    },
  ] = useAddNetworkMutation();

  const handleTabClick = (networkName) => {
    setActiveTab(networkName);
  };

  const handleAddAccount = async (networkPlatform) => {
    if (loggedInType === "creator") {
      if (networkPlatform === "YOUTUBE") {
        await window.initTokenClient((response) => {
          const payload = {
            authorization_code: response.code,
            platform: PLATFORMS.Youtube,
          };
          addNetwork(payload);
        });
        window.getToken();
      }
    } else if (loggedInType === "agency") {
      dispatch(setModalAddNetwork(true));
      dispatch(setPlatform(networkPlatform));
    }
  };

  useEffect(() => {
    if (isNetworkSuccess) {
      dispatch(setModalSuccessNetwork(true));
      dispatch(updateNetworksField(networkData?.data.network));
    }
  }, [isNetworkSuccess, dispatch, networkData]);

  useEffect(() => {
    if (isNetworkError) {
      dispatch(
        setModalErrorNetwork({
          modal: true,
          message: networkError?.data?.error,
        })
      );
    }
  }, [isNetworkError, dispatch, networkError]);

  const renderEmptyContent = (networkPlatform) => (
    <div className="profileEmptyContent">
      <div className="profileEmptyContentInfo">
        <img src={noData} alt="no-data" />
        {loggedInType === userType &&
        userLoggedIn?.username === creatorRedux?.username ? (
          <p>
            {t("profile.creator.no_data_networks1")}{" "}
            {networkPlatform.toLowerCase()}{" "}
            {t("profile.creator.no_data_networks2")}
          </p>
        ) : (
          <p>
            {t("profile.creator.no_data_networks_otherPOV_1")}{" "}
            {networkPlatform.toLowerCase()}{" "}
            {t("profile.creator.no_data_networks_otherPOV_2")}
          </p>
        )}

        {loggedInType === userType &&
          userLoggedIn?.username === creatorRedux?.username && (
            <>
              {networkPlatform !== "YOUTUBE" ? (
                <button className="profileComingSoon">
                  <p>{t("profile.coming_soon")}</p>
                </button>
              ) : (
                <button
                  onClick={() => handleAddAccount(networkPlatform)}
                  className="profileEmptyButton"
                >
                  <img src={plus} alt="add" />
                  <p>{t("profile.creator.btn_add_account")}</p>
                </button>
              )}
            </>
          )}
        {loggedInType === "agency" &&
          userLoggedIn?.username === creatorRedux?.agency.username && (
            <>
              {networkPlatform !== "YOUTUBE" ? (
                <button className="profileComingSoon">
                  <p>{t("profile.coming_soon")}</p>
                </button>
              ) : (
                <button
                  onClick={() => handleAddAccount(networkPlatform)}
                  className="profileEmptyButton"
                >
                  <img src={plus} alt="add" />
                  <p>{t("profile.creator.btn_add_account")}</p>
                </button>
              )}
            </>
          )}
      </div>
    </div>
  );

  const dropdownRef = useRef(null);
  const [dropdltNet, setDropdltNet] = useState({});

  const handleModalDelete = ({ e, id, name }) => {
    e.stopPropagation();
    dispatch(setModalDeleteNet({ modal: true, id: id, name: name }));
    setDropdltNet(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      if (!event.target.classList.contains("cardDeleteIcon")) {
        setDropdltNet({});
      }
    }
  };

  useEffect(() => {
    if (dropdltNet) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdltNet]);

  const handleDeleteNetwork = (e, id) => {
    e.stopPropagation();
    setDropdltNet((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const renderNetworksContent = (networks) => (
    <div className="profileNetworksContent">
      {networks.map((network, index) => {
        const analytics =
          network[`analytics_${network.platform.toLowerCase()}`];
        return (
          <div key={index} className="profileNetworksContentInfo">
            <div className="profileNetworksContentInfoAvatar">
              <img src={network.profile_img_url || avatar} alt="avatar" />
              <p>{network.name}</p>
            </div>
            {analytics && (
              <>
                <div className="profileNetworksContentInfoStats">
                  <label>Suscriptores</label>
                  <p>{analytics.subs_count}</p>
                </div>
                <div className="profileNetworksContentInfoStats">
                  <label>Videos</label>
                  <p>{analytics.videos_count}</p>
                </div>
                {loggedInType === userType &&
                  userLoggedIn?.username === creatorRedux?.username && (
                    <div className="profileNetworksDelete">
                      <img
                        className="cardDeleteIcon"
                        onClick={(e) =>
                          handleDeleteNetwork(e, network.account_id)
                        }
                        src={group}
                        alt="group"
                        width={20}
                        height={24}
                      />
                      {dropdltNet[network.account_id] && (
                        <div
                          ref={dropdownRef}
                          onClick={(e) =>
                            handleModalDelete({
                              e,
                              id: network.account_id,
                              name: network.name,
                            })
                          }
                          className="cardDeleteNet"
                        >
                          <img
                            src={iconDelete}
                            alt="delete"
                            width={16}
                            height={16}
                          />
                          <span>{t("general.btn_remove")}</span>
                        </div>
                      )}
                    </div>
                  )}
              </>
            )}
          </div>
        );
      })}
      {loggedInType === userType &&
        userLoggedIn?.username === creatorRedux?.username && (
          <button
            onClick={() => handleAddAccount(activeTab)}
            className="profileNetworkContentButton"
          >
            <img src={plus} alt="add" />
            <p>{t("profile.creator.btn_add_account")}</p>
          </button>
        )}
      {loggedInType === "agency" &&
        userLoggedIn?.username === creatorRedux?.agency.username && (
          <button
            onClick={() => handleAddAccount(activeTab)}
            className="profileNetworkContentButton"
          >
            <img src={plus} alt="add" />
            <p>{t("profile.creator.btn_add_account")}</p>
          </button>
        )}
    </div>
  );

  const filteredNetworks =
    creatorRedux?.networks?.filter(
      (network) => network.platform === activeTab
    ) || [];

  return (
    <div className="profileNetworksContainer">
      <div className="profileNetworksTabs">
        {userType === "creator" && (
          <>
            {networks.map((network) => (
              <div
                key={network.platform}
                className={`profileNetworks${
                  activeTab === network.platform ? "Active" : ""
                }Tab`}
                onClick={() => handleTabClick(network.platform)}
              >
                <img src={network.icon} alt={network.title} />
                {activeTab === network.platform && (
                  <p className="profileNetworksActiveTabNet">{network.title}</p>
                )}
              </div>
            ))}
          </>
        )}
      </div>

      {userType === "creator" &&
        (filteredNetworks.length === 0
          ? renderEmptyContent(activeTab)
          : renderNetworksContent(filteredNetworks))}
    </div>
  );
};

export default ProfileNetworksCreator;
