import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalNetwork: false,
  platform: null,
};

export const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    setModalAddNetwork: (state, action) => {
      state.modalNetwork = action.payload;
    },
    setPlatform: (state, action) => {
      state.platform = action.payload;
    },
  },
});

export default networkSlice.reducer;
export const { setModalAddNetwork, setPlatform } = networkSlice.actions;
