import signUpImage from "../../../../assets/images/sign-up-person-1.svg";
import "../SignUpPage.styles.css";
import SignUpIntegration from "../../../../components/auth/SignUpIntegration/SignUpIntegration";
import { useDispatch, useSelector } from "react-redux";
import { setModalErrorNetwork } from "../../../../redux/modalSlice";
import { useTranslation } from "react-i18next";
import InformationModal from "../../../../components/UI/ModalMessages/InformationModal/InformationModal";
import HeaderOut from "../../../../components/UI/HeaderOut/HeaderOut";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function IntegrationPage() {
  const navigate = useNavigate();
  const modalError = useSelector((state) => state.modal.modalNetwork);
  const userLoggedIn = useSelector((state) => state.login.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!userLoggedIn.type && !userLoggedIn.email) {
      navigate("/");
    }
  }, [userLoggedIn, navigate]);

  const modalData = {
    icon: "warning",
    title: t("profile.networks.integration.error"),
    text: t("profile.networks.integration.already_integrated"),
    textButtonClose: t("general.btn_return"),
  };

  const handleClose = () => {
    dispatch(setModalErrorNetwork({ modal: false, message: null }));
  };
  return (
    <>
      <HeaderOut wType={true} />
      <div className="signUpContainer">
        <div className="signUpFormContainer">
          <SignUpIntegration />
        </div>
        <div className="signUpImageContainer">
          <img src={signUpImage} alt="sign-Up" className="signUpImage" />
        </div>
        {modalError.modal && (
          <InformationModal
            data={modalData}
            onClose={handleClose}
            onAccept={null}
          />
        )}
      </div>
    </>
  );
}

export default IntegrationPage;
