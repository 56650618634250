import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../../constants";

export const networkApi = createApi({
  reducerPath: "networkQuery",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["network"],
  endpoints: (builder) => ({
    addNetwork: builder.mutation({
      query: (data) => {
        return {
          url: "/network",
          method: "POST",
          body: data,
        };
      },
    }),
    addNetworkExternal: builder.mutation({
      query: (data) => {
        return {
          url: "/network/external",
          method: "POST",
          body: data,
        };
      },
    }),
    deleteNetwork: builder.mutation({
      query: (data) => {
        return {
          url: `/network/${data}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useAddNetworkMutation,
  useAddNetworkExternalMutation,
  useDeleteNetworkMutation,
  middleware,
} = networkApi;
export default networkApi.reducer;
