import React, { useEffect } from "react";
import HeaderIn from "../../components/UI/HeaderIn/HeaderIn";
import Opportunities from "../../components/Opportunities/Opportunities";
import "./OpportunitiesPage.styles.css";
import { useGetOpportunityMutation } from "../../redux/api/opportunities/opportunitiesQuery";
import { useDispatch } from "react-redux";
import { setOpportunitiesData } from "../../redux/api/opportunities/opportunitiesSlice";

const OpportunitiesPage = () => {
  const dispatch = useDispatch();
  const [
    getOpportunity,
    { data: opportunityData, isSuccess: isOpportunitySuccess },
  ] = useGetOpportunityMutation();
  useEffect(() => {
    getOpportunity();
  }, [getOpportunity]);
  useEffect(() => {
    if (isOpportunitySuccess) {
      dispatch(setOpportunitiesData(opportunityData.data));
    }
  }, [isOpportunitySuccess, opportunityData, dispatch]);

  return (
    <div className="opportunitiesContainer">
      <HeaderIn />
      <Opportunities />
    </div>
  );
};

export default OpportunitiesPage;
