import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "./FinishChatModal.styles.css";
import { useEffect } from "react";
import {
  setModalFinishConversation,
  setSelectedConversationChat,
  updateConversationField,
  updateSelectedConversation,
} from "../../../../redux/api/conversations/conversationsSlice";
import {
  useGetConversationByIdMutation,
  useUpdateConversationByIdMutation,
} from "../../../../redux/api/conversations/conversationsQuery";

const FinishChatModal = ({ id, type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [
    UpdateConversationById,
    {
      data: updateConversationData,
      isSuccess: isUpdateSuccess,
      /* isLoading: isUpdateLoading,
      isError: isUpdateError,
      error: updateConversationError, */
    },
  ] = useUpdateConversationByIdMutation();
  const [
    getConversationById,
    {
      data: conversationIdData,
      isSuccess: isConversationIdSuccess,
      /* isLoading: isConversationIdLoading,
      isError: isConversationIdError,
      error: conversationIdLoading, */
    },
  ] = useGetConversationByIdMutation();

  const handleFinish = () => {
    if (type === "ADVERTISER") {
      UpdateConversationById({ id: id, status: "FINISH_APPROVAL_PENDING" });
    } else {
      UpdateConversationById({ id: id, status: "FINISHED" });
    }
  };
  const handleClose = () => {
    dispatch(
      setModalFinishConversation({ modal: false, conversationId: null })
    );
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      getConversationById(id);
      if (
        updateConversationData.conversation.status === "FINISH_APPROVAL_PENDING"
      ) {
        dispatch(
          updateConversationField({
            id: id,
            field: "status",
            value: updateConversationData.conversation.status,
          })
        );

        dispatch(
          updateSelectedConversation({
            field: "status",
            value: updateConversationData.conversation.status,
          })
        );
      }
      if (updateConversationData.conversation.status === "FINISHED") {
        dispatch(
          updateConversationField({
            id: id,
            field: "status",
            value: updateConversationData.conversation.status,
          })
        );

        dispatch(
          updateSelectedConversation({
            field: "status",
            value: updateConversationData.conversation.status,
          })
        );
      }
    }
  }, [
    isUpdateSuccess,
    dispatch,
    updateConversationData,
    getConversationById,
    id,
  ]);

  useEffect(() => {
    if (isConversationIdSuccess) {
      dispatch(setSelectedConversationChat(conversationIdData.messages));
      dispatch(
        setModalFinishConversation({ modal: false, conversationId: null })
      );
    }
  }, [isConversationIdSuccess, conversationIdData, dispatch]);

  return (
    <div className="modalFinishChat">
      <div className="modalFinishChat__container">
        <p className="modalFinishChat__title">
          {t("chat.messages.btn_finish")}
        </p>
        <p className="modalFinishChat__subtitle">
          {t("chat.messages.confirm_finish_text")}
        </p>
        <div className="modalFinishChat__buttons">
          <button onClick={handleFinish}>
            {t("chat.messages.btn_yes_finish")}
          </button>
          <button onClick={handleClose}>{t("general.btn_cancel")}</button>
        </div>
      </div>
    </div>
  );
};

export default FinishChatModal;
