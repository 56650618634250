import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setEdit } from "../../../redux/api/user/userSlice";
import plusIcon from "../../../assets/icons/IconPlus.svg";

import "../styles/ProfileAbout.styles.css";

const ProfileAboutAdvertiser = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userLoggedIn = useSelector((state) => state.login.user);
  const loggedInType = userLoggedIn?.type?.toLowerCase() || "";
  const advertiserRedux = useSelector(
    (state) => state.userAdvertiser.advertiser
  );
  const handleEdit = () => {
    dispatch(setEdit(true));
  };
  return (
    <>
      {advertiserRedux?.description && (
        <div className="profileAboutContainer">
          <div>
            <p>{t("profile.about_me")}</p>
            <p>{advertiserRedux?.description}</p>
          </div>
        </div>
      )}
      {!advertiserRedux?.description &&
        loggedInType === "advertiser" &&
        userLoggedIn?.username === advertiserRedux?.username && (
          <div className="profileAboutEmptyContainer">
            <div className="profileAboutEmpty">
              <p className="profileAboutEmptyTitle">
                {t("profile.advertiser.description_empty")}
              </p>
              <button onClick={handleEdit} className="profileAboutEmptyButton">
                <img src={plusIcon} alt="add" width={20} height={20} />
                {t("general.btn_add_description")}
              </button>
            </div>
          </div>
        )}
    </>
  );
};

export default ProfileAboutAdvertiser;
