import React from "react";
import Select, { components } from "react-select";
import "./SelectCustom.styles.css";

const CheckboxOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="optionContainer">
        <span className={`statusColor${props.data.value}`}></span>
        <label className="customValue">{props.data.label}</label>
      </div>
    </components.Option>
  );
};

const singleContainerStatus = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <div className="optionContainer">
        <span className={`statusColor${props.data.value}`}></span>
        <label className="customValue">{props.data.label}</label>
      </div>
    </components.SingleValue>
  );
};

const SelectCustomStatus = ({ options, value, onChange, placeholder }) => {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      outline: "none",
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "48px",
      outline: "none",
      boxShadow: state.isFocused ? "0 0 0 0 rgba(0, 123, 255, 0.3)" : "none",
      borderRadius: "8px",
      border: "1px solid #CBD2E0",
      background: "#FFF",
      color: "#000",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        outline: "none",
        boxShadow: state.isFocused ? "0 0 0 0 rgba(0, 123, 255, 0.3)" : "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#000",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "150%",
      letterSpacing: "-0.16px",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "#EAEDF2",
      },
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      display: "none",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#cbd2e0",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "150%",
      letterSpacing: "-0.16px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
      cursor: "pointer",
    }),
  };
  return (
    <>
      <Select
        components={{
          Option: CheckboxOption,
          SingleValue: singleContainerStatus,
        }}
        defaultValue={options[0]}
        options={options}
        value={value}
        onChange={onChange}
        styles={customStyles}
        isSearchable={false}
      />
    </>
  );
};

export default SelectCustomStatus;
