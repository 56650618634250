import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agency: null,
  addCreator: false,
};

export const userAgencySlice = createSlice({
  name: "userAgency",
  initialState,
  reducers: {
    setUserAgencyData: (state, action) => {
      state.agency = action.payload;
    },
    updateUserAgencyField: (state, action) => {
      const { field, value } = action.payload;
      if (state.agency) {
        state.agency[field] = value;
      }
    },
    clearAgencyData: (state, action) => {
      state.agency = action.payload;
    },
    setAddCreator: (state, action) => {
      state.addCreator = action.payload;
    },
    addCreatorToAgency: (state, action) => {
      if (state.agency && Array.isArray(state.agency.creators)) {
        state.agency.creators.push(action.payload);
      } else if (state.agency) {
        state.agency.creators = [action.payload];
      }
    },
  },
});

export const {
  setUserAgencyData,
  updateUserAgencyField,
  clearAgencyData,
  setAddCreator,
  addCreatorToAgency,
} = userAgencySlice.actions;

export default userAgencySlice.reducer;
