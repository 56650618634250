import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../../constants";

export const tagApi = createApi({
  reducerPath: "tagQuery",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["tag"],
  endpoints: (builder) => ({
    getTags: builder.mutation({
      query: (data) => {
        return {
          url: "/tag",
          method: "GET",
          body: data,
        };
      },
    }),
  }),
});

export const { useGetTagsMutation, middleware } = tagApi;
export default tagApi.reducer;
