import React from "react";
import iconPlus from "../../../assets/icons/IconPlus.svg";
import "./Kanban.styles.css";
import empty_img from "../../../assets/images/undraw_add_files.png";
import KanbanTable from "./KanbanTable";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setModalCreateOpp } from "../../../redux/api/opportunities/opportunitiesSlice";

const Kanban = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const opportunities = useSelector(
    (state) => state.opportunities.opportunities
  );

  const handleCreateOpp = () => {
    dispatch(setModalCreateOpp(true));
  };

  return (
    <>
      {opportunities.length === 0 ? (
        <div className="kanbanEmpty">
          <div className="emptyContainer">
            <div className="emptyImage">
              <img src={empty_img} alt="empty_opp" />
            </div>
            <div className="emptyText">
              <p>{t("opportunities.empty_title")}</p>
              <p>{t("opportunities.empty_description")}</p>
            </div>
            <div className="emptyButton">
              <button onClick={handleCreateOpp}>
                <img src={iconPlus} alt="add_opp" />
                <p>{t("opportunities.empty_btn")}</p>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <KanbanTable />
      )}
    </>
  );
};

export default Kanban;
