import PropTypes from "prop-types";
import AuthError from "./AuthError";
import "./Auth.styles.css";
import { useState } from "react";
import iconEyeOn from "../../assets/icons/IconEyeOn.svg";
import iconEyeOff from "../../assets/icons/IconEyeOff.svg";

const AuthField = ({
  label,
  placeholder,
  type,
  value,
  onChange,
  error,
  errorRes,
  errorRtkEmail,
  errorRtkUsername,
}) => {
  const [inputType, setInputType] = useState("password");
  const handleViewPassword = (e) => {
    e.preventDefault();
    setInputType(inputType === "password" ? "text" : "password");
  };

  return (
    <div>
      <label className="authFormLabel" htmlFor={type}>
        {label}
      </label>
      <div>
        <div
          className={`authFormInputWrapper ${
            error || errorRes || errorRtkEmail || errorRtkUsername
              ? "inputError"
              : ""
          }`}
        >
          <input
            type={type === "password" ? inputType : type}
            className="authFormInput"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
          {type === "password" && (
            <div onClick={handleViewPassword} className="authFormInputIcon">
              <img
                className="authFormEyeIcon"
                src={inputType === "password" ? iconEyeOn : iconEyeOff}
                alt="eyeIcon"
                height={20}
                width={20}
              />
            </div>
          )}
        </div>
        {error && <AuthError message={error.message} />}
        {errorRtkEmail && <AuthError message={errorRtkEmail} />}
        {errorRtkUsername && <AuthError message={errorRtkUsername} />}
      </div>
    </div>
  );
};

AuthField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.object,
  errorRes: PropTypes.string,
  errorRtkEmail: PropTypes.string,
  errorRtkUsername: PropTypes.string,
};

export default AuthField;
