import conversations from "../../../assets/icons/IconChat.svg";
//import campaigns from "../../../assets/icons/IconCampaigns.svg";
import opportunities from "../../../assets/icons/IconCampaigns.svg";
//import discovery from "../../../assets/icons/IconDiscovery.svg";

export const sections = [
  /* {
    title: "campaigns",
    path: "campaigns",
    type: ["CREATOR", "AGENCY", "ADVERTISER"],
    icon: campaigns,
  }, */
  {
    title: "opportunities",
    path: "opportunities",
    type: ["CREATOR", "AGENCY"],
    icon: opportunities,
  },
  /* {
    title: "discovery",
    path: "discovery",
    type: ["ADVERTISER"],
    icon: discovery,
  }, */
   {
    title: "conversations",
    path: "conversations",
    type: ["CREATOR", "AGENCY", "ADVERTISER"],
    icon: conversations,
  }, 
];
