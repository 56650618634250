import React, { useEffect, useRef, useState } from "react";
import influenlyBlack from "../../../assets/logo/influenly.svg";
import profile from "../../../assets/icons/iconAccount.svg";
import profileSettings from "../../../assets/icons/IconProfile.svg";
import logout from "../../../assets/icons/IconLogout.svg";
import { useNavigate } from "react-router-dom";
import "./HeaderIn.styles.css";
import { sections } from "./menuSections";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../redux/api/auth/login/loginSlice";
import { useLogoutUserMutation } from "../../../redux/api/auth/login/loginQuery";

const HeaderIn = () => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const handleNavigate = (e) => {
    navigate(e);
  };
  const userLoggedIn = useSelector((state) => state.login.user) || "";

  const [
    logoutQuery,
    {
      /* data: logoutQueryData, */
      isSuccess: isLogoutQuerySuccess,
      /* isLoading: isLogoutQueryLoading,
      isError: isLogoutQueryError,
      error: LogoutQueryError, */
    },
  ] = useLogoutUserMutation();

  const filteredSections = sections.filter((section) =>
    section.type.includes(userLoggedIn.type)
  );

  const handleSettings = (e) => {
    e.preventDefault();
    setSettings((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      if (!event.target.classList.contains("headerInProfile")) {
        setSettings(false);
      }
    }
  };

  useEffect(() => {
    if (settings) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [settings]);

  const handleLogout = () => {
    logoutQuery();
  };
  const handleProfile = () => {
    navigate(`/${userLoggedIn.type.toLowerCase()}/${userLoggedIn.username}`);
  };

  useEffect(() => {
    if (isLogoutQuerySuccess) {
      dispatch(
        logoutUser({
          user: {
            type: null,
            username: null,
          },
        })
      );
      navigate("/");
    }
  }, [isLogoutQuerySuccess, dispatch, navigate]);
  return (
    <div className="headerInContainer">
      <div
        onClick={() =>
          handleNavigate(
            `/${userLoggedIn.type.toLowerCase()}/${userLoggedIn.username}`
          )
        }
        className="headerInIconContainer"
      >
        <img src={influenlyBlack} alt="influenly" />
      </div>
      <div className="headerInMenuContainer">
        <div className="headerInSectionContainer">
          {filteredSections.map((item) => (
            <div
              key={item.title}
              onClick={() => handleNavigate(`/${item.path}`)}
              className="headerInSection"
            >
              <label>{t(`core.header.sections.${item.title}`)}</label>
              <img src={item.icon} alt="icon" />
            </div>
          ))}
        </div>
        <img
          className="headerInProfile"
          onClick={(e) => handleSettings(e)}
          src={profile}
          alt="account"
        />
      </div>
      {settings && (
        <div
          className="headerInDropdown"
          ref={dropdownRef}
          onClick={(e) => e.stopPropagation()}
        >
          <div onClick={handleProfile} className="headerInDropdownProfile">
            <img src={profileSettings} alt="profileSettings" />
            <label>{t("core.header.my_profile")}</label>
          </div>
          <div onClick={handleLogout} className="headerInDropdownLogout">
            <img src={logout} alt="logout" />
            <label>{t("core.header.logout")}</label>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderIn;
