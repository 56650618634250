import React, { useEffect } from "react";
import YTConnect from "../../../assets/icons/IconYoutubeConnect.svg";
import "./SignUpIntegration.styles.css";
import {
  useAddNetworkExternalMutation,
  useAddNetworkMutation,
} from "../../../redux/api/network/networkQuery";
import { PLATFORMS } from "../../../redux/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalErrorNetwork,
  setModalSuccessNetwork,
} from "../../../redux/modalSlice";
import { useTranslation } from "react-i18next";

const SignUpIntegration = ({ UrlCC }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginUserData = useSelector((state) => state.login.user);

  const [
    addNetwork,
    {
      data: networkData,
      isSuccess: isNetworkSuccess,
      isError: isNetworkError,
      error: networkError,
    },
  ] = useAddNetworkMutation();
  const [
    addNetworkExternal,
    {
      data: networkExternalData,
      isSuccess: isNetworkExternalSuccess,
      isError: isNetworkExternalError,
      error: networkExternalError,
    },
  ] = useAddNetworkExternalMutation();

  useEffect(() => {
    if (isNetworkSuccess && networkData.ok) {
      navigate(
        `/${loginUserData.type.toLowerCase()}/${loginUserData.username}`
      );
    }
  }, [isNetworkSuccess, networkData, loginUserData, navigate]);

  useEffect(() => {
    if (isNetworkExternalSuccess && networkExternalData.ok) {
      dispatch(setModalSuccessNetwork(true));
    }
  }, [networkExternalData, isNetworkExternalSuccess, dispatch]);

  useEffect(() => {
    if (isNetworkError || isNetworkExternalError) {
      dispatch(
        setModalErrorNetwork({
          modal: true,
          message: networkError?.data.error || networkExternalError?.data?.error,
        })
      );
    }
  }, [
    isNetworkError,
    dispatch,
    networkError,
    isNetworkExternalError,
    networkExternalError,
  ]);

  const handleConnect = async () => {
    await window.initTokenClient((response) => {
      if (UrlCC) {
        const payload = {
          authorization_code: response.code,
          platform: PLATFORMS.Youtube,
          connection_code: UrlCC,
        };
        addNetworkExternal(payload);
      } else {
        const payload = {
          authorization_code: response.code,
          platform: PLATFORMS.Youtube,
        };
        addNetwork(payload);
      }
    });
    window.getToken();
  };
  return (
    <div className="integrationContainer">
      <div className="integrationTitleContainer">
        <label>{t("onboarding.youtube_integration.title")}</label>
        <div className="integrationParagraphContainer">
          <label>{t("onboarding.youtube_integration.paragraph_1")}</label>
          <label>{t("onboarding.youtube_integration.paragraph_2")}</label>
        </div>
      </div>
      <button className="integrationButton" onClick={handleConnect}>
        <img src={YTConnect} alt="YTConnect" />
        {t("onboarding.youtube_integration.button_connect")}
      </button>
    </div>
  );
};

export default SignUpIntegration;
