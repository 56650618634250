import React from "react";
import iconEdit from "../../../assets/icons/IconEdit.svg";
import "../styles/ProfileCard.styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setEdit } from "../../../redux/api/user/userSlice";
import advertiserProfileImg from "../../../assets/images/advertiser_default_img.jpg";

const ProfileCardAdvertiser = ({ userType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userLoggedIn = useSelector((state) => state.login.user);
  const loggedInType = userLoggedIn?.type?.toLowerCase() || "";
  const advertiserRedux = useSelector(
    (state) => state.userAdvertiser.advertiser
  );

  const handleEdit = () => {
    dispatch(setEdit(true));
  };

  const handleImageError = (e) => {
    e.target.src = advertiserProfileImg;
  };

  return (
    <>
      <div className="profileCard">
        <div className="profileCardBackgroundContainer">
          <div className={`profileCardBackgroundImg ${userType}CardBackground`}>
            <p className={`profileCardBackgroundTag ${userType}Tag`}>
              {t(`profile.${userType}.title`)}
            </p>
          </div>
          <div
            className={`profileCardAvatar${
              userType === "creator" ? "Creator" : ""
            }`}
          >
            <img
              className={`${userType}ProfileAvatarImg`}
              src={
                advertiserRedux?.profile_img_url
                  ? advertiserRedux?.profile_img_url
                  : advertiserProfileImg
              }
              alt="avatar"
              onError={handleImageError}
            />
          </div>
        </div>
        <div className="profileCardInfoContainer">
          <div className="profileCardInfo">
            <div className="profileCardInfoUsername">
              <p>{advertiserRedux?.username}</p>
            </div>
            {advertiserRedux?.tags && (
              <div className="profileCardInfoTags">
                {advertiserRedux?.tags.map((tag, index) => (
                  <span key={index}>
                    {t(`onboarding.content.tag_list.${tag}`)}
                  </span>
                ))}
              </div>
            )}
          </div>
          {loggedInType === userType &&
            userLoggedIn?.username === advertiserRedux?.username && (
              <div onClick={handleEdit} className="profileCardEdit">
                <p>{t("profile.btn_edit")}</p>
                <img src={iconEdit} alt="edit" />
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default ProfileCardAdvertiser;
