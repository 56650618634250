import React from "react";
import Footer from "../../components/UI/Footer/Footer";
import HeaderOut from "../../components/UI/HeaderOut/HeaderOut";
import "./PrivacyPolicy.styles.css";
import { useTranslation } from "react-i18next";

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="privacyPageContainer">
        <HeaderOut />
        <div className="privacyContainer">
          <div className="privacyTitle">
            <p>
              {t("landing.private_policy.title_1")}
              <span>{t("landing.private_policy.title_2")}</span>
            </p>
          </div>
          <div className="privacyContent">
            <p>{t("landing.private_policy.paragraph_1")}</p>
            <p>{t("landing.private_policy.paragraph_2")}</p>
            <p>{t("landing.private_policy.paragraph_3")}</p>
            <p>{t("landing.private_policy.paragraph_4")}</p>
            <div>
              <p>{t("landing.private_policy.paragraph_5")}</p>
              <p>{t("landing.private_policy.paragraph_6")}</p>
              <ul>
                <li>{t("landing.private_policy.paragraph_7")}</li>
                <li>{t("landing.private_policy.paragraph_8")}</li>
                <li>{t("landing.private_policy.paragraph_9")}</li>
                <li>{t("landing.private_policy.paragraph_10")}</li>
                <li>{t("landing.private_policy.paragraph_11")}</li>
              </ul>
            </div>
            <p>{t("landing.private_policy.paragraph_13")}</p>
            <p>{t("landing.private_policy.paragraph_14")}</p>
            <p>{t("landing.private_policy.paragraph_15")}</p>
            <p>{t("landing.private_policy.paragraph_16")}</p>
            <p>{t("landing.private_policy.paragraph_17")}</p>
            <p>{t("landing.private_policy.paragraph_18")}</p>
            <p>{t("landing.private_policy.paragraph_19")}</p>
            <p>{t("landing.private_policy.paragraph_20")}</p>
            <p>{t("landing.private_policy.paragraph_21")}</p>
            <p>{t("landing.private_policy.paragraph_22")}</p>
            <p>{t("landing.private_policy.paragraph_23")}</p>
            <p>{t("landing.terms_of_service.paragraph_14")}</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
