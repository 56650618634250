import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setModalVerification } from "../../../redux/modalSlice";
import EmailVerificationForm from "../../../components/auth/EmailVerificationForm/EmailVerificationForm";
import InformationModal from "../../../components/UI/ModalMessages/InformationModal/InformationModal";
import signUpImage from "../../../assets/images/sign-up-person-1.svg";
import "./EmailVerificationPage.styles.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import HeaderOut from "../../../components/UI/HeaderOut/HeaderOut";

const EmailVerificationPage = () => {
  const navigate = useNavigate();
  const dispatchEvent = useDispatch();
  const { t } = useTranslation();
  const modalVerification = useSelector(
    (state) => state.modal.modalVerification
  );
  const userData = useSelector((state) => state.signup.data);

  const isUserDataInvalid = ({ email, username, type }) => {
    return !email || !username || !type;
  };

  useEffect(() => {
    if (isUserDataInvalid(userData)) {
      navigate("/");
    }
  }, [userData, navigate]);

  const modalData = {
    icon: "email",
    title: t("landing.sign_up.modal_messages.new_code_verification"),
    textButtonClose: t("general.btn_close"),
  };

  const handleClose = () => {
    dispatchEvent(setModalVerification(false));
  };
  return (
    <>
      <HeaderOut wType={true} />
      <div className="emailVerificationContainer">
        <div className="emailVerificationFormContainer">
          <EmailVerificationForm />
        </div>
        <div className="emailVerificationImageContainer">
          <img
            src={signUpImage}
            alt="emailVerification"
            className="emailVerificationImage"
          />
        </div>
        {modalVerification && (
          <InformationModal
            data={modalData}
            onClose={handleClose}
            onAccept={null}
          />
        )}
      </div>
    </>
  );
};

export default EmailVerificationPage;
