import { useTranslation } from "react-i18next";
import { setModalDeleteOpp } from "../../../../redux/modalSlice";
import { useDispatch } from "react-redux";
import { useDeleteOpportunityMutation } from "../../../../redux/api/opportunities/opportunitiesQuery";
import "./DeleteOppModal.styles.css";
import { useEffect } from "react";
import { deleteOpportunity } from "../../../../redux/api/opportunities/opportunitiesSlice";

const DeleteOppModal = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [deleteOpportunityMutation, { isSuccess: isDeleteSuccess }] =
    useDeleteOpportunityMutation();

  const handleDelete = () => {
    deleteOpportunityMutation(id);
  };
  const handleClose = () => {
    dispatch(setModalDeleteOpp({ modal: false, id: null }));
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(deleteOpportunity(id));
      dispatch(setModalDeleteOpp({ modal: false, id: null }));
    }
  }, [isDeleteSuccess, dispatch, id]);

  return (
    <div className="modalDeleteOpp">
      <div className="modalDeleteOpp__container">
        <p className="modalDeleteOpp__title">
          {t("opportunities.modal.delete.title")}
        </p>
        <p className="modalDeleteOpp__subtitle">
          {t("opportunities.modal.delete.subtitle")}
        </p>
        <div className="modalDeleteOpp__buttons">
          <button onClick={handleDelete}>
            {t("opportunities.modal.delete.btn_delete")}
          </button>
          <button onClick={handleClose}>
            {t("opportunities.modal.delete.btn_cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteOppModal;
