import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../../../constants";

export const emailVerificationApi = createApi({
  reducerPath: "emailVerificationQuery",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["emailVerification"],
  endpoints: (builder) => ({
    verificationCode: builder.mutation({
      query: (data) => {
        return {
          url: "/user/email/verification",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useVerificationCodeMutation, middleware } = emailVerificationApi;
export default emailVerificationApi.reducer;
