import React from "react";
import iconEdit from "../../../assets/icons/IconEdit.svg";
import iconEmail from "../../../assets/icons/IconEmailPurple.svg";
import agencyProfileImg from "../../../assets/images/agency_default_img.jpg";
import "../styles/ProfileCard.styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setEdit } from "../../../redux/api/user/userSlice";
import { setModalAddConversation } from "../../../redux/api/conversations/conversationsSlice";

const ProfileCardAgency = ({ userType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userLoggedIn = useSelector((state) => state.login.user);
  const loggedInType = userLoggedIn?.type?.toLowerCase() || "";
  const agencyRedux = useSelector((state) => state.userAgency.agency);
  const creatorsCount = agencyRedux?.creators?.length || 0;
  const oneCreator = agencyRedux?.creators?.length === 1;

  const handleEdit = () => {
    dispatch(setEdit(true));
  };

  const handleChat = () => {
    dispatch(
      setModalAddConversation({
        modal: true,
        userId: agencyRedux?.user.id,
      })
    );
  };

  const handleImageError = (e) => {
    e.target.src = agencyProfileImg;
  };

  return (
    <>
      <div className="profileCard">
        <div className="profileCardBackgroundContainer">
          <div className={`profileCardBackgroundImg ${userType}CardBackground`}>
            <p className={`profileCardBackgroundTag ${userType}Tag`}>
              {t(`profile.${userType}.title`)}
            </p>
          </div>
          <div
            className={`profileCardAvatar${
              userType === "creator" ? "Creator" : ""
            }`}
          >
            <img
              className={`${userType}ProfileAvatarImg`}
              src={
                agencyRedux?.profile_img_url
                  ? agencyRedux?.profile_img_url
                  : agencyProfileImg
              }
              alt="avatar"
              onError={handleImageError}
            />
          </div>
        </div>
        <div className="profileCardInfoContainer">
          <div className="profileCardInfo">
            <div className="profileCardInfoUsername">
              <p>{agencyRedux?.username}</p>
            </div>
            {userType === "agency" && creatorsCount > 0 && (
              <div className="profileCardInfoAgency">
                <p>
                  {t("profile.agency.manage.1")}
                  <label>
                    {creatorsCount}{" "}
                    {oneCreator
                      ? t("profile.agency.manage.2")
                      : t("profile.agency.manage.3")}
                  </label>
                </p>
              </div>
            )}
          </div>
          {loggedInType === userType &&
            userLoggedIn?.username === agencyRedux?.username && (
              <div onClick={handleEdit} className="profileCardEdit">
                <p>{t("profile.btn_edit")}</p>
                <img src={iconEdit} alt="edit" />
              </div>
            )}
          {loggedInType === "advertiser" && (
            <div onClick={handleChat} className="profileCardEdit">
              <p>{t("profile.btn_talk")}</p>
              <img src={iconEmail} alt="talk" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileCardAgency;
