import React from "react";
import Select, { components } from "react-select";
import creatorProfileImg from "../../../assets/images/creator_default_img.jpg";
import "./SelectCustom.styles.css";

const CheckboxOption = React.forwardRef((props, ref) => {
  return (
    <components.Option {...props} ref={ref}>
      <div className="optionContainer">
        <input
          className="inputCheckbox"
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        {props.data.data.profile_img_url && (
          <img
            className="imgOption"
            src={props.data.data.profile_img_url}
            alt="profile"
            onError={(e) => {
              e.target.src = creatorProfileImg;
            }}
          />
        )}
        <label className="customValue">{props.data.label}</label>
      </div>
    </components.Option>
  );
});

const CustomValueContainer = ({ children, ...props }) => {
  const [values, input] = children;
  const customValues = Array.isArray(values)
    ? values.map((value, index) => (
        <span key={value.props.data.value} style={{ marginRight: "4px" }}>
          {value.props.data.label}
          {index < values.length - 1 ? ", " : ""}
        </span>
      ))
    : values;

  return (
    <components.ValueContainer {...props}>
      {customValues}
      {input}
    </components.ValueContainer>
  );
};

const SelectCustomCreators = ({ options, placeholder, onChange, value }) => {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      outline: "none",
    }),
    control: (provided, state) => ({
      ...provided,
      outline: "none",
      height: "48px",
      boxShadow: state.isFocused ? "0 0 0 0 rgba(0, 123, 255, 0.3)" : "none",
      borderRadius: "8px",
      border: "1px solid #CBD2E0",
      background: "#FFF",
      color: "#000",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "150%",
      letterSpacing: "-0.16px",
      "&:hover": {
        cursor: "pointer",
        outline: "none",
        boxShadow: state.isFocused ? "0 0 0 0 rgba(0, 123, 255, 0.3)" : "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#000",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "#EAEDF2",
      },
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      display: "none",
    }),
    ClearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#cbd2e0",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "150%",
      letterSpacing: "-0.16px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
    }),
  };

  const handleChange = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((option) => ({
      value: option.value,
      label: option.label,
    }));
    onChange(formattedOptions);
  };
  return (
    <Select
      components={{
        Option: CheckboxOption,
        ValueContainer: CustomValueContainer,
      }}
      options={options}
      isMulti
      isClearable={false}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      hideSelectedOptions={false}
      placeholder={placeholder}
      styles={customStyles}
      onChange={handleChange}
      value={value}
    />
  );
};

export default SelectCustomCreators;
