import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    username: null,
    email: null,
    password: null,
    type: null,
  },
};

export const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setUserRegister: (state, action) => {
      state.data.username = action.payload.username;
      state.data.email = action.payload.email;
      state.data.password = action.payload.password;
      state.data.type = action.payload.type;
    },
    setType: (state, action) => {
      state.data.type = action.payload;
    },
  },
});

export default signupSlice.reducer;
export const { setUserRegister, setType } = signupSlice.actions;
