import React, { useState } from "react";
import "./TypeSelection.styles.css";
import help from "../../../assets/icons/IconHelp.svg";
import { useTranslation } from "react-i18next";

const TypeSelectionCard = ({
  id,
  imgSrc,
  alt,
  title,
  description,
  onClick,
  onClickHelp,
}) => {
  const { t } = useTranslation();
  const [noShadow, setNoShadow] = useState(false);

  const handleHelpMouseEnter = () => setNoShadow(true);
  const handleHelpMouseLeave = () => setNoShadow(false);

  const handleHelpClick = (e) => {
    e.stopPropagation();
    if (id !== "advertiser") {
      onClickHelp(id);
    }
  };

  const handleCardClick = () => {
    
      onClick(id);
    
  };
  return (
    <div
      onClick={handleCardClick}
      className="typeSelectionCard"
    >
      <div className="typeSelectionCardTextContainer">
        <p>{title}</p>
        <p>{description}</p>
      </div>
      <img src={imgSrc} alt={alt} />
      <div
        onMouseEnter={handleHelpMouseEnter}
        onMouseLeave={handleHelpMouseLeave}
        onClick={handleHelpClick}
        className="helpSection"
      >
        <p>{t("landing.btn_know_more")}</p>
        <img src={help} alt="help" />
      </div>
    </div>
  );
};

export default TypeSelectionCard;
