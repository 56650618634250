import Conversations from "../../components/Conversations/Conversations";
import HeaderIn from "../../components/UI/HeaderIn/HeaderIn";

const ConversationsPage = () => {
  return (
    <div>
      <HeaderIn />
      <Conversations />
    </div>
  );
};

export default ConversationsPage;
