import React, { useEffect, useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthField from "../AuthField";
import AuthButton from "../AuthButton";
import AuthError from "../AuthError";
import { signupSchema } from "../../../validationsSchema/signupSchema";
import { setUserRegister } from "../../../redux/api/auth/signup/signupSlice";
import { useGetUserAvailableMutation } from "../../../redux/api/user/userQuery";
import { useVerificationCodeMutation } from "../../../redux/api/auth/emailVerification/emailVerificationQuery";
import "./SignUpForm.styles.css";

const SignUpForm = ({ type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [errorUsername, setErrorUsername] = useState(null);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorRes, setErrorRes] = useState(null);

  const userData = useSelector((state) => state.signup.data);

  const [
    getUserAvailable,
    {
      data: isAvailableData,
      isSuccess: isAvailableSuccess,
      isLoading: isAvailableLoading,
      isError: isAvailableError,
      error: availableError,
    },
  ] = useGetUserAvailableMutation();

  const [
    verificationCode,
    {
      isSuccess: isVerificationSuccess,
      isError: isVerificationCodeError,
      error: verificationCodeError,
    },
  ] = useVerificationCodeMutation();

  const typeUser = type?.toUpperCase();

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: zodResolver(signupSchema(t, type)),
  });

  const onSubmit = useCallback(
    async (data) => {
      setErrorRes(null);
      setLoader(true);
      try {
        await getUserAvailable({ ...data, type: typeUser }).unwrap();
        dispatch(setUserRegister({ ...data, type: typeUser }));
      } catch (error) {
        console.error("Failed to sign up: ", error);
      }
    },
    [dispatch, getUserAvailable, typeUser]
  );

  useEffect(() => {
    if (isAvailableSuccess) {
      setLoader(false);
    }
  }, [isAvailableSuccess]);

  useEffect(() => {
    if (!isAvailableData) return;

    const { valid, username, email } = isAvailableData;

    if (valid) {
      verificationCode({ email: userData?.email });
    } else {
      if (username === "not_available") {
        setErrorUsername(t(`landing.sign_up.${type}.already_exists_error`));
      }
      if (email === "not_available") {
        setErrorEmail(t("landing.sign_up.email.already_exists_error"));
      }
    }
  }, [isAvailableData, t, type, verificationCode, userData]);

  useEffect(() => {
    if (isAvailableError) {
      console.error(availableError);
    }
  }, [isAvailableError, availableError]);
  useEffect(() => {
    if (isVerificationCodeError) {
      console.error(verificationCodeError);
    }
  }, [isVerificationCodeError, verificationCodeError]);

  useEffect(() => {
    if (isVerificationSuccess) {
      navigate("/auth/email-verification");
    }
  }, [isVerificationSuccess, navigate]);

  const handleInputChange = (field) => {
    if (field === "username") {
      setErrorUsername(null);
    }
    if (field === "email") {
      setErrorEmail(null);
    }
    clearErrors(field);
    setErrorRes(null);
  };

  return (
    <form className="signUpForm" onSubmit={handleSubmit(onSubmit)}>
      <div className="signUpFormCard">
        <h2 className="signUpFormTitle">{t("landing.sign_up.title")}</h2>
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <AuthField
              label={t(`landing.sign_up.${type}.label`)}
              placeholder={t(`landing.sign_up.${type}.placeholder`)}
              type="text"
              value={field.value || ""}
              onChange={(e) => {
                field.onChange(e);
                handleInputChange("username");
              }}
              error={errors.username}
              errorRtkUsername={errorUsername}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <AuthField
              label={t("landing.sign_up.email.label")}
              placeholder={t("landing.sign_up.email.placeholder")}
              type="email"
              value={field.value || ""}
              onChange={(e) => {
                field.onChange(e);
                handleInputChange("email");
              }}
              error={errors.email}
              errorRtkEmail={errorEmail}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <AuthField
              label={t("landing.sign_up.password.label")}
              placeholder={t("landing.sign_up.password.placeholder")}
              type="password"
              value={field.value || ""}
              onChange={(e) => {
                field.onChange(e);
                handleInputChange("password");
              }}
              error={errors.password}
            />
          )}
        />
        {errorRes && <AuthError message={errorRes} />}
      </div>

      <AuthButton
        isLoading={isAvailableLoading || loader}
        text={t("general.btn_continue")}
      />
      <p className="terms-cond-text">
        {t("landing.sign_up.text_1")}
        <a href="/terms-of-service">{t("landing.sign_up.terms_conditions")}</a>
        {t("landing.sign_up.text_2")}{" "}
        <a href="/privacy-policy">{t("landing.sign_up.privacy_policies")}</a>.
      </p>
      <p className="signUpFormNotRegistered">
        {t("landing.sign_up.already_registered")}
        <a href="/auth/signin">{t("landing.sign_up.login")}</a>
      </p>
    </form>
  );
};

export default SignUpForm;
