import React from "react";
import iconEdit from "../../../assets/icons/IconEdit.svg";
import iconEmail from "../../../assets/icons/IconEmailPurple.svg";
import creatorProfileImg from "../../../assets/images/creator_default_img.jpg";
import "../styles/ProfileCard.styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setEdit } from "../../../redux/api/user/userSlice";
import { setModalAddConversation } from "../../../redux/api/conversations/conversationsSlice";

const ProfileCardCreator = ({ userType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userLoggedIn = useSelector((state) => state.login.user);
  const loggedInType = userLoggedIn?.type?.toLowerCase() || "";
  const creatorRedux = useSelector((state) => state.userCreator.creator);

  const handleEdit = () => {
    dispatch(setEdit(true));
  };

  const handleChat = () => {
    dispatch(
      setModalAddConversation({
        modal: true,
        userId: creatorRedux?.user.id,
        conversationId: null,
      })
    );
  };

  const handleImageError = (e) => {
    e.target.src = creatorProfileImg;
  };

  if (!creatorRedux) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="profileCard">
        <div className="profileCardBackgroundContainer">
          <div className={`profileCardBackgroundImg ${userType}CardBackground`}>
            <p className={`profileCardBackgroundTag ${userType}Tag`}>
              {t(`profile.${userType}.title`)}
            </p>
          </div>
          <div
            className={`profileCardAvatar${
              userType === "creator" ? "Creator" : ""
            }`}
          >
            <img
              className={`${userType}ProfileAvatarImg`}
              src={
                creatorRedux?.profile_img_url
                  ? creatorRedux?.profile_img_url
                  : creatorProfileImg
              }
              alt="avatar"
              onError={handleImageError}
            />
          </div>
        </div>
        <div className="profileCardInfoContainer">
          <div className="profileCardInfo">
            <div className="profileCardInfoUsername">
              <p>{creatorRedux?.username}</p>
              {userType === "creator" && creatorRedux?.agency && (
                <label>
                  {t("profile.creator.manage")}
                  <span>{creatorRedux?.agency?.username}</span>
                </label>
              )}
            </div>
            {creatorRedux?.tags && (
              <div className="profileCardInfoTags">
                {creatorRedux?.tags.map((tag, index) => (
                  <span key={index}>
                    {t(`onboarding.content.tag_list.${tag}`)}
                  </span>
                ))}
              </div>
            )}
          </div>
          {userLoggedIn?.username === creatorRedux?.username &&
            loggedInType === "creator" && (
              <div onClick={handleEdit} className="profileCardEdit">
                <p>{t("profile.btn_edit")}</p>
                <img src={iconEdit} alt="edit" />
              </div>
            )}
          {userLoggedIn?.username === creatorRedux?.agency?.username &&
            loggedInType === "agency" && (
              <div onClick={handleEdit} className="profileCardEdit">
                <p>{t("profile.btn_edit")}</p>
                <img src={iconEdit} alt="edit" />
              </div>
            )}
          {loggedInType === "advertiser" && (
            <div onClick={handleChat} className="profileCardEdit">
              <p>{t("profile.btn_talk")}</p>
              <img src={iconEmail} alt="talk" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileCardCreator;
