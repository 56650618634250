import { isRejectedWithValue } from "@reduxjs/toolkit";
import { getNavigate } from "../../utils/authNavigator";
import { logoutUser } from "../api/auth/login/loginSlice";
import { match } from "path-to-regexp";

// Lista de rutas excluidas (pueden incluir rutas dinámicas)
const excludedRoutes = [
  "/",
  "/auth/signup",
  "/how-it-works",
  "/terms-of-service",
  "/auth/email-verification",
  "/auth/signup/integration",
  "/about-us",
  "/privacy-policy",
  "/network/external/:connectioncode",
];

// Crea funciones de coincidencia para cada ruta excluida
const routeMatchers = excludedRoutes.map((route) =>
  match(route, { decode: decodeURIComponent })
);

// Verificar si una ruta actual coincide con la lista de exclusión
const isExcludedRoute = (currentRoute) => {
  return routeMatchers.some((matchFn) => matchFn(currentRoute));
};

const authMiddleware = (storeAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { status } = action.payload || {};
    if (status === 401) {
      const navigate = getNavigate();
      if (navigate) {
        const currentRoute = window.location.pathname;
        if (!isExcludedRoute(currentRoute)) {
          navigate("/auth/signin");
          storeAPI.dispatch(
            logoutUser({
              user: {
                type: null,
                username: null,
              },
            })
          );
        }
      } else {
        console.error(
          "No se ha inicializado 'navigate'. Asegúrate de usar setNavigate."
        );
      }
    }
  }
  return next(action);
};

export default authMiddleware;
