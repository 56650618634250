import { z } from "zod";

export const signinSchema = (t) => z.object({
  email: z
    .string()
    .min(1, { message: t("landing.sign_up.email.required_error") })
    .email({ message: t("landing.sign_up.email.pattern_error") }),
  password: z
    .string()
    .min(8, { message: t("landing.sign_up.password.minlength_error") })
    .regex(/^[a-zA-Z0-9._%+-]+$/, {
      message: t("landing.sign_up.password.pattern_error"),
    }),
});
