import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  advertiser: null,
};

export const userAdvertiserSlice = createSlice({
  name: "userAdvertiser",
  initialState,
  reducers: {
    setUserAdvertiserData: (state, action) => {
      state.advertiser = action.payload;
    },
    updateUserAdvertiserField: (state, action) => {
      const { field, value } = action.payload;
      if (state.advertiser) {
        state.advertiser[field] = value;
      }
    },
    clearAdvertiserData: (state, action) => {
      state.advertiser = action.payload;
    },
  },
});

export const { setUserAdvertiserData, updateUserAdvertiserField, clearAdvertiserData } =
  userAdvertiserSlice.actions;

export default userAdvertiserSlice.reducer;
