import React from "react";
import HeaderOut from "../../components/UI/HeaderOut/HeaderOut";
import Footer from "../../components/UI/Footer/Footer";
import "./AboutUs.styles.css";
import { useTranslation } from "react-i18next";

const AboutUsPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="aboutPageContainer">
        <HeaderOut />
        <div className="aboutContainer">
          <p className="aboutTitle">
            {t("landing.about_us.title_1")}
            <span>{t("landing.about_us.title_2")}</span>,{" "}
            <span>{t("landing.about_us.title_4")}</span>
            {t("landing.about_us.title_5")}
            <span>{t("landing.about_us.title_6")}</span>
            {t("landing.about_us.title_7")}
          </p>
          <div className="aboutDescription">
            <p>{t("landing.about_us.paragraph_1")}</p>
            <p>{t("landing.about_us.paragraph_2")}</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUsPage;
