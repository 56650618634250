import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import group from "../../../assets/icons/IconGroup.svg";
import iconDelete from "../../../assets/icons/IconDelete.svg";
import creatorProfileImg from "../../../assets/images/creator_default_img.jpg";
import "./CardOpp.styles.css";
import { useDispatch } from "react-redux";
import { setModalDeleteOpp } from "../../../redux/modalSlice";

const CardOpp = ({
  id,
  title,
  name,
  description,
  status,
  onClick,
  creators,
}) => {
  const [dropdlt, setDropdlt] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const statusLowerCase = status.toLowerCase();

  const handleDelete = (e) => {
    e.stopPropagation();
    if (!dropdlt) {
      setDropdlt(true);
    } else {
      setDropdlt(false);
    }
  };
  const handleModalDelete = (e) => {
    e.stopPropagation();
    dispatch(setModalDeleteOpp({ modal: true, id: id }));
    setDropdlt(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      if (!event.target.classList.contains("cardDeleteIcon")) {
        setDropdlt(false);
      }
    }
  };

  useEffect(() => {
    if (dropdlt) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdlt]);

  const handleImageError = (e) => {
    e.target.src = creatorProfileImg;
  };

  return (
    <div onClick={onClick} className="cardOpp">
      <div className="cardOppHeader">
        <div>
          <p className="cardTitle">{title}</p>
          <p className="cardName">{name}</p>
        </div>
        <div className="cardCreators">
          {creators &&
            creators.length > 0 &&
            creators.map((creator, index) => (
              <img
                key={index}
                src={creator.profile_img_url}
                alt="profile"
                width={24}
                height={24}
                style={{ zIndex: creators.length - index }}
                onError={handleImageError}
              />
            ))}
        </div>
      </div>
      <p className="cardDescription">{description}</p>
      <div className="cardFooter">
        <span className={`${statusLowerCase}Card statusCard`}>
          {t(`opportunities.status.${statusLowerCase}`)}
        </span>
        <div>
          <img
            className="cardDeleteIcon"
            onClick={(e) => handleDelete(e)}
            src={group}
            alt="group"
            width={16}
            height={16}
          />
          {dropdlt && (
            <div
              ref={dropdownRef}
              onClick={(e) => handleModalDelete(e)}
              className="cardDelete"
            >
              <img src={iconDelete} alt="" width={16} height={16} />
              <span>{t("opportunities.delete_btn")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardOpp;
