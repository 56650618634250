import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalError: false,
  modalVerification: false,
  modalNetwork: { modal: false, message: null },
  modalSuccessNetwork: false,
  modalErrorEditOpp: { modal: false, message: null },
  modalErrorCreateOpp: { modal: false, message: null },
  modalDeleteOpp: { modal: false, id: null },
  modalDeleteNet: { modal: false, id: null, name: null },
  modalErrorEditUser: { modal: false, message: null },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalError: (state, action) => {
      state.modalError = action.payload;
    },
    setModalVerification: (state, action) => {
      state.modalVerification = action.payload;
    },
    setModalErrorNetwork: (state, action) => {
      state.modalNetwork = action.payload;
    },
    setModalSuccessNetwork: (state, action) => {
      state.modalSuccessNetwork = action.payload;
    },
    setModalErrorEditOpp: (state, action) => {
      state.modalErrorEditOpp = action.payload;
    },
    setModalErrorCreateOpp: (state, action) => {
      state.modalErrorCreateOpp = action.payload;
    },
    setModalDeleteOpp: (state, action) => {
      state.modalDeleteOpp = action.payload;
    },
    setModalDeleteNet: (state, action) => {
      state.modalDeleteNet = action.payload;
    },
    setModalErrorEditUser: (state, action) => {
      state.modalErrorEditUser = action.payload;
    },
  },
});

export default modalSlice.reducer;
export const {
  setModalError,
  setModalVerification,
  setModalErrorNetwork,
  setModalSuccessNetwork,
  setModalErrorCreateOpp,
  setModalErrorEditOpp,
  setModalDeleteOpp,
  setModalDeleteNet,
  setModalErrorEditUser,
} = modalSlice.actions;
