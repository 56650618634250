import React, { useEffect } from "react";
import HeaderIn from "../../components/UI/HeaderIn/HeaderIn";
import Profile from "../../components/Profile/Profile";
import "./ProfilePage.styles.css";
import { useDispatch } from "react-redux";
import { useGetUserMutation } from "../../redux/api/user/userQuery";
import {
  setUser,
  updateUserLoggedField,
} from "../../redux/api/auth/login/loginSlice";

const ProfilePage = () => {
  const dispatch = useDispatch();

  const [getUser, { data: userData, isSuccess: isDataUserSuccess }] =
    useGetUserMutation();

  useEffect(() => {
    getUser();
  }, [getUser]);
  useEffect(() => {
    if (isDataUserSuccess) {
      dispatch(
        updateUserLoggedField({
          field: "username",
          value: userData?.data.user.username,
        })
      );
      dispatch(
        updateUserLoggedField({
          field: "email",
          value: userData?.data.user.email,
        })
      );
      dispatch(
        updateUserLoggedField({
          field: "type",
          value: userData?.data.user.type,
        })
      );
    }
  }, [isDataUserSuccess, userData, dispatch]);

  return (
    <div className="profilePageContainer">
      <HeaderIn />
      <Profile />
    </div>
  );
};

export default ProfilePage;
