import React from "react";
import Influenly from "../../../assets/logo/influenly-logo-word.svg";
import "./HeaderOut.styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HeaderOut = ({ wType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isRootPath =
    location.pathname === "/" ||
    location.pathname === "/about-us" ||
    location.pathname === "/privacy-policy" ||
    location.pathname === "/terms-of-service";
  const handleNavigate = (e) => {
    navigate(e);
  };
  return (
    <>
      {isRootPath ? (
        <div className="headerOutLandingContainer">
          <div
            onClick={() => handleNavigate("/")}
            className="headerOutIconContainer"
          >
            <img src={Influenly} alt="influenly" />
          </div>
          {isRootPath && (
            <button
              onClick={() => handleNavigate("/auth/signin")}
              className="headerOutLandingButton"
            >
              {t("core.header.sign_in")}
            </button>
          )}
        </div>
      ) : (
        <div
          className="headerOutContainer"
          style={{ background: wType ? "white" : "##eaedf2" }}
        >
          <div
            onClick={() => handleNavigate("/")}
            className="headerOutIconContainer"
          >
            <img src={Influenly} alt="influenly" />
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderOut;
