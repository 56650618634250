import React from "react";
import "./Footer.styles.css";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/logo/influenly-logo.svg";
import copy from "../../../assets/icons/IconCopyright.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleHome = (e) => {
    e.preventDefault();
    navigate("/");
  };
  const handleAbout = (e) => {
    e.preventDefault();
    navigate("/about-us");
  };
  const handleTerms = (e) => {
    e.preventDefault();
    navigate("/terms-of-service");
  };
  const handlePolicy = (e) => {
    e.preventDefault();
    navigate("/privacy-policy");
  };
  return (
    <div className="footer-container">
      <div className="footer-info">
        <img onClick={handleHome} src={logo} alt="influenly_logo" />
        <p onClick={handleAbout}>{t("core.footer.about_us")}</p>
      </div>
      <div className="footer-links">
        <div className="copyright-container">
          <span>influenly</span>
          <img src={copy} alt="copyright" />
          <span>2024</span>
        </div>
        <span onClick={handleTerms}>{t("core.footer.terms_of_service")}</span>
        <span onClick={handlePolicy}>{t("core.footer.privacy_policy")}</span>
      </div>
    </div>
  );
};

export default Footer;
