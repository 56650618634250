import undrawCreator from "../../../assets/images/undraw-creator.svg";
import undrawAdvertiser from "../../../assets/images/undraw-advertiser.svg";
import undrawAgency from "../../../assets/images/undraw-agency.svg";
import { useTranslation } from "react-i18next";

const TYPES = () => {
  const { t } = useTranslation();
  return [
    {
      id: "creator",
      imgSrc: undrawCreator,
      alt: "img-creator",
      title: t("landing.sign_up.creator.title"),
      description: t("landing.sign_up.creator.description"),
    },
    {
      id: "agency",
      imgSrc: undrawAgency,
      alt: "img-agency",
      title: t("landing.sign_up.agency.title"),
      description: t("landing.sign_up.agency.description"),
    },
    {
      id: "advertiser",
      imgSrc: undrawAdvertiser,
      alt: "img-advertiser",
      title: t("landing.sign_up.advertiser.title"),
      description: t("landing.sign_up.advertiser.description"),
    },
  ];
};

export default TYPES;
