import React from "react";
import noData from "../../assets/images/undraw-no-data.svg";
import star from "../../assets/icons/IconStar.svg";
import "./styles/ProfileReputation.styles.css";

const ProfileReputation = ({ userType }) => {
  return (
    <div className="profileReputationContainer">
      <div className="profileReputationTabs">
        <div className="profileReputationActiveTab">
          <img src={star} alt="" />
          <p>Reputación</p>
        </div>
      </div>
      <div className="profileReputationContent">
        <div className="profileReputationContentInfo">
          <img src={noData} alt="no-data" />
          <p>
            Aún no has recibido suficientes valoraciones para mostrar
            información.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileReputation;
