import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../../constants";

export const conversationsApi = createApi({
  reducerPath: "conversationsQuery",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["network"],
  endpoints: (builder) => ({
    getConversations: builder.mutation({
      query: (data) => {
        return {
          url: "/chat/conversation",
          method: "GET",
          body: data,
        };
      },
    }),
    addConversation: builder.mutation({
      query: (data) => {
        return {
          url: "/chat/conversation",
          method: "POST",
          body: data,
        };
      },
    }),
    getConversationById: builder.mutation({
      query: (id) => {
        return {
          url: `/chat/conversation/${id}/message`,
          method: "GET",
        };
      },
    }),
    UpdateConversationById: builder.mutation({
      query: (data) => {
        return {
          url: `/chat/conversation/${data.id}`,
          method: "PATCH",
          body: { status: data.status, message: data.message },
        };
      },
    }),
    UpdateMessageById: builder.mutation({
      query: (data) => {
        return {
          url: `/chat/message/${data.id}`,
          method: "PATCH",
          body: { type: data.type },
        };
      },
    }),
  }),
});

export const {
  useGetConversationsMutation,
  useAddConversationMutation,
  useGetConversationByIdMutation,
  useUpdateConversationByIdMutation,
  useUpdateMessageByIdMutation,
  middleware,
} = conversationsApi;
export default conversationsApi.reducer;
