import PropTypes from "prop-types";
import "./Auth.styles.css";

const AuthButton = ({ isLoading, text }) => (
  <button className="authFormButton" type="submit" disabled={isLoading}>
    {isLoading ? "cargando..." : text}
  </button>
);

AuthButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default AuthButton;
