import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // provisorio para persistir local
  user: {},
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    updateUserLoggedField: (state, action) => {
      const { field, value } = action.payload;
      if (state.user) {
        state.user[field] = value;
      }
    },
    logoutUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export default loginSlice.reducer;
export const { setUser, logoutUser, updateUserLoggedField } =
  loginSlice.actions;
