import SignInForm from "../../../components/auth/SignInForm/SignInForm";
import signInImage from "../../../assets/images/sign-in-person-1.svg";
import "./SignInPage.styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InformationModal from "../../../components/UI/ModalMessages/InformationModal/InformationModal";
import { setModalError } from "../../../redux/modalSlice";
import HeaderOut from "../../../components/UI/HeaderOut/HeaderOut";

function SignInPage() {
  const modalError = useSelector((state) => state.modal.modalError);
  const dispatchEvent = useDispatch();
  const { t } = useTranslation();
  const modalData = {
    icon: "warning",
    title: t("landing.sign_in.modal_messages.error"),
    text: t("landing.sign_in.modal_messages.error_desc"),
    textButtonClose: t("general.btn_return"),
  };

  const handleClose = () => {
    dispatchEvent(setModalError(false));
  };

  return (
    <>
      <HeaderOut wType={true} />
      <div className="signInContainer">
        <div className="signInFormContainer">
          <SignInForm />
        </div>
        <div className="signInImageContainer">
          <img src={signInImage} alt="sign-in" className="signInImage" />
        </div>
        {modalError && (
          <InformationModal
            data={modalData}
            onClose={handleClose}
            onAccept={null}
          />
        )}
      </div>
    </>
  );
}

export default SignInPage;
