import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";

import loginReducer from "./api/auth/login/loginSlice";
import loginQuery, {
  middleware as loginMiddleware,
} from "./api/auth/login/loginQuery";

import signupReducer from "./api/auth/signup/signupSlice";
import signupQuery, {
  middleware as signupMiddleware,
} from "./api/auth/signup/signupQuery";

import userReducer from "./api/user/userSlice";
import userCreatorReducer from "./api/user/userCreatorSlice";
import userAgencyReducer from "./api/user/userAgencySlice";
import userAdvertiserReducer from "./api/user/userAdvertiserSlice";
import userQuery, { middleware as userMiddleware } from "./api/user/userQuery";
import userImgQuery, {
  middleware as userProfileImgMiddleware,
} from "./api/user/userImgQuery";

import emailVerificationReducer from "./api/auth/emailVerification/emailVerificationSlice";
import emailVerificationQuery, {
  middleware as emailVerificationMiddleware,
} from "./api/auth/emailVerification/emailVerificationQuery";

import networkReducer from "./api/network/networkSlice";
import networkQuery, {
  middleware as networkMiddleware,
} from "./api/network/networkQuery";

import opportunitiesReducer from "./api/opportunities/opportunitiesSlice";
import opportunitiesQuery, {
  middleware as opportunitiesMiddleware,
} from "./api/opportunities/opportunitiesQuery";
import conversationsReducer from "./api/conversations/conversationsSlice";
import conversationsQuery, {
  middleware as conversationsMiddleware,
} from "./api/conversations/conversationsQuery";

import tagQuery, { middleware as tagMiddleware } from "./api/tag/tagQuery";

import modalReducer from "./modalSlice";
import authMiddleware from "./middleware/authMiddleware";

const combinedReducers = combineReducers({
  login: loginReducer,
  loginQuery,
  signup: signupReducer,
  signupQuery,
  userQuery,
  userImgQuery,
  user: userReducer,
  userCreator: userCreatorReducer,
  userAgency: userAgencyReducer,
  userAdvertiser: userAdvertiserReducer,
  emailVerification: emailVerificationReducer,
  emailVerificationQuery,
  networkQuery,
  network: networkReducer,
  opportunitiesQuery,
  opportunities: opportunitiesReducer,
  conversationsQuery,
  conversations: conversationsReducer,
  tagQuery,
  modal: modalReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return combinedReducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      loginMiddleware,
      signupMiddleware,
      emailVerificationMiddleware,
      userMiddleware,
      userProfileImgMiddleware,
      networkMiddleware,
      opportunitiesMiddleware,
      conversationsMiddleware,
      tagMiddleware,
      authMiddleware
    ),
});

setupListeners(store.dispatch);
export default store;
