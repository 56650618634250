import { z } from "zod";

export const signupSchema = (t, typefori18n) =>
  z.object({
    email: z
      .string()
      .min(1, { message: t("landing.sign_up.email.required_error") })
      .email({ message: t("landing.sign_up.email.pattern_error") }),
    password: z
      .string()
      .min(8, { message: t("landing.sign_up.password.minlength_error") })
      .regex(/^[a-zA-Z0-9._%+-]+$/, {
        message: t("landing.sign_up.password.pattern_error"),
      }),
    username: z
      .string()
      .min(3, { message: t(`landing.sign_up.${typefori18n}.minlength_error`) })
      .regex(/^[a-zA-Z0-9]+$/, {
        message: t(`landing.sign_up.${typefori18n}.pattern_error`),
      }),
  });
