import { useEffect } from "react";
import IconCloseChat from "../../../assets/icons/IconCloseChat.svg";
import IconCloseChatGray from "../../../assets/icons/IconCloseChatGray.svg";
import creatorProfileImg from "../../../assets/images/creator_default_img.jpg";
import advertiserProfileImg from "../../../assets/images/advertiser_default_img.jpg";
import agencyProfileImg from "../../../assets/images/agency_default_img.jpg";
import { useUpdateConversationByIdMutation } from "../../../redux/api/conversations/conversationsQuery";
import {
  setModalAddConversation,
  setModalFinishConversation,
  updateConversationField,
  updateSelectedConversation,
} from "../../../redux/api/conversations/conversationsSlice";

import "./HeaderConversations.styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const HeaderConversations = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userLoggedIn = useSelector((state) => state.login.user);
  const selectedConversation = useSelector(
    (state) => state.conversations.selectedConversation
  );

  const [
    updateConversationById,
    { data: updateConversationsData, isSuccess: isUpdateConversationsSuccess },
  ] = useUpdateConversationByIdMutation();

  const handleFinishChat = () => {
    if (selectedConversation?.status === "FINISH_APPROVAL_PENDING") {
      dispatch(
        setModalFinishConversation({
          modal: true,
          conversationId: selectedConversation.id,
          type: null,
        })
      );
    } else {
      dispatch(
        setModalFinishConversation({
          modal: true,
          conversationId: selectedConversation.id,
          type: "ADVERTISER",
        })
      );
    }
  };

  const handleNewChat = () => {
    dispatch(
      setModalAddConversation({
        modal: true,
        userId: selectedConversation.creator
          ? selectedConversation.creator.id
          : selectedConversation.agency.id,
        conversationId: selectedConversation.id,
      })
    );
  };
  const handleInitChat = (conversationId) => {
    updateConversationById({ id: conversationId, status: "ACTIVE" });
  };

  const handleRejectInitChat = (conversationId) => {
    updateConversationById({ id: conversationId, status: "INIT_REJECTED" });
  };
  const handleRejectFinishedChat = (conversationId) => {
    updateConversationById({ id: conversationId, status: "FINISH_REJECTED" });
  };

  useEffect(() => {
    if (isUpdateConversationsSuccess) {
      const { status } = updateConversationsData.conversation;

      const updateStatus = () => {
        dispatch(
          updateConversationField({
            id: selectedConversation.id,
            field: "status",
            value: status,
          })
        );
        dispatch(
          updateSelectedConversation({
            field: "status",
            value: status,
          })
        );
      };

      const statusesToUpdate = [
        "ACTIVE",
        "FINISHED",
        "INIT_REJECTED",
        "FINISH_REJECTED",
      ];

      if (statusesToUpdate.includes(status)) {
        updateStatus();
      }
    }
  }, [isUpdateConversationsSuccess, updateConversationsData, dispatch]);

  const handleImageError = (e) => {
    e.target.src = selectedConversation?.creator
      ? creatorProfileImg
      : selectedConversation?.agency
      ? agencyProfileImg
      : advertiserProfileImg;
  };

  return (
    <div className="headerConversations__container">
      <div className="headerConversations__user">
        {selectedConversation ? (
          <img
            src={
              selectedConversation?.creator
                ? selectedConversation?.creator.profile_img_url
                : selectedConversation?.agency
                ? selectedConversation?.agency.profile_img_url
                : selectedConversation?.advertiser.profile_img_url
            }
            alt={
              selectedConversation?.creator
                ? selectedConversation?.creator.username
                : selectedConversation?.agency
                ? selectedConversation?.agency.username
                : selectedConversation?.advertiser.username
            }
            width={32}
            height={32}
            onError={handleImageError}
          />
        ) : (
          <div style={{ width: "64px", height: "64px" }}></div>
        )}
        <label>
          {selectedConversation?.creator
            ? selectedConversation?.creator.username
            : selectedConversation?.agency
            ? selectedConversation?.agency.username
            : selectedConversation?.advertiser.username}
        </label>
      </div>
      {userLoggedIn?.type === "ADVERTISER" && (
        <div>
          {selectedConversation?.status === "INIT_APPROVAL_PENDING" && (
            <>
              <button
                onClick={handleFinishChat}
                className="headerConversations__buttonPending"
                disabled
              >
                <span>{t("chat.messages.btn_finish")}</span>
                <img
                  src={IconCloseChatGray}
                  alt="IconCloseChatGray"
                  width={20}
                  height={20}
                />
              </button>
            </>
          )}
          {selectedConversation?.status === "ACTIVE" && (
            <button
              onClick={handleFinishChat}
              className="headerConversations__button"
            >
              <span>{t("chat.messages.btn_finish")}</span>
              <img
                src={IconCloseChat}
                alt="IconCloseChat"
                width={20}
                height={20}
              />
            </button>
          )}
          {selectedConversation?.status === "FINISHED" && (
            <button
              onClick={handleNewChat}
              className="headerConversations__button"
            >
              <span>{t("chat.messages.btn_init")}</span>
              <img
                src={IconCloseChat}
                alt="IconCloseChat"
                width={20}
                height={20}
              />
            </button>
          )}
          {selectedConversation?.status === "FINISH_APPROVAL_PENDING" && (
            <button
              onClick={handleFinishChat}
              className="headerConversations__buttonPending"
              disabled
            >
              <span>{t("chat.messages.btn_finish")}</span>
              <img
                src={IconCloseChatGray}
                alt="IconCloseChatGray"
                width={20}
                height={20}
              />
            </button>
          )}
          {selectedConversation?.status === "INIT_REJECTED" && (
            <p>
              {selectedConversation.agency ? "La agencia" : "El creador"}{" "}
              {t("chat.messages.not_finished")}
            </p>
          )}
          {selectedConversation?.status === "FINISH_REJECTED" && (
            <p>
              {selectedConversation.agency ? "La agencia" : "El creador"}{" "}
              {t("chat.messages.not_finished")}
            </p>
          )}
        </div>
      )}
      {(userLoggedIn?.type === "CREATOR" ||
        userLoggedIn?.type === "AGENCY") && (
        <div>
          {selectedConversation?.status === "INIT_APPROVAL_PENDING" && (
            <>
              <div className="message_buttonsInit">
                <button
                  className="button__init"
                  onClick={() => handleInitChat(selectedConversation.id)}
                >
                  {t("chat.messages.btn_init")}
                </button>
                <button
                  className="button__cancel"
                  onClick={() => handleRejectInitChat(selectedConversation.id)}
                >
                  {t("chat.messages.btn_reject")}
                </button>
              </div>
            </>
          )}
          {/* {selectedConversation?.status === "FINISHED" && (
            <button
              onClick={handleInitChat}
              className="headerConversations__button"
            >
              <span>Iniciar conversación</span>
              <img
                src={IconCloseChat}
                alt="IconCloseChat"
                width={20}
                height={20}
              />
            </button>
          )} */}
          {selectedConversation?.status === "FINISH_APPROVAL_PENDING" && (
            <>
              <div className="message_buttonsInit">
                {t("chat.messages.finisher_message_creator")}
                <button
                  className="button__init"
                  onClick={() => handleFinishChat(selectedConversation.id)}
                >
                  {t("chat.messages.btn_only_finish")}
                </button>
                <button
                  className="button__cancel"
                  onClick={() =>
                    handleRejectFinishedChat(selectedConversation.id)
                  }
                >
                  {t("chat.messages.btn_reject")}
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default HeaderConversations;
