import React from "react";
import "./KanbanTable.styles.css";
import CardOpp from "./CardOpp";
import { useDispatch, useSelector } from "react-redux";
import { setModalEditOpp } from "../../../redux/api/opportunities/opportunitiesSlice";
import CreateOpp from "../CreateOpp/CreateOpp";
import { useGetOpportunityByIdMutation } from "../../../redux/api/opportunities/opportunitiesQuery";

const KanbanTable = () => {
  const editOpp = useSelector((state) => state.opportunities.modalEditOpp);
  const userLoggedIn = useSelector((state) => state.login.user);
  const dispatch = useDispatch();
  const opportunities = useSelector(
    (state) => state.opportunities.opportunities
  );

  const [getOpportunityById, { data: opportunityByIdData }] =
    useGetOpportunityByIdMutation();

  const kanbanData = [
    {
      title: "Pendiente",
      status: "PENDING",
      colorClass: "statusColorPending",
    },
    {
      title: "Negociación",
      status: "NEGOTIATION",
      colorClass: "statusColorNegotiation",
    },
    {
      title: "Finalizada",
      status: "FINISHED",
      colorClass: "statusColorFinished",
    },
    {
      title: "Cancelada",
      status: "CANCELED",
      colorClass: "statusColorCanceled",
    },
  ];

  const handleEdit = (e) => {
    dispatch(setModalEditOpp(true));
    getOpportunityById(e);
  };

  const handleCloseEditOpp = () => {
    dispatch(setModalEditOpp(false));
  };

  return (
    <div className="kanbanTable">
      {kanbanData.map((column) => (
        <div className="kanbanTableColumn" key={column.status}>
          <div className="kanbanTableHeader">
            <span className={column.colorClass}></span>
            <p className="kanbanTableStatus">{column.title}</p>
            <p className="countOpp">{column.count}</p>
          </div>
          <div className="cardsContainer">
            {userLoggedIn.type === "AGENCY"
              ? opportunities
                  .filter((opp) => opp.status === column.status)
                  .map((opp) => (
                    <CardOpp
                      key={opp.id}
                      id={opp.id}
                      title={opp.title}
                      name={opp.advertiser_name}
                      description={opp.description}
                      status={opp.status}
                      onClick={() => handleEdit(opp.id)}
                      creators={opp.creators}
                    />
                  ))
              : opportunities
                  .filter((opp) => opp.status === column.status)
                  .map((opp) => (
                    <CardOpp
                      key={opp.id}
                      id={opp.id}
                      title={opp.title}
                      name={opp.advertiser_name}
                      description={opp.description}
                      status={opp.status}
                      onClick={() => handleEdit(opp.id)}
                    />
                  ))}
          </div>
        </div>
      ))}
      {editOpp && (
        <CreateOpp
          onClose={handleCloseEditOpp}
          isEdit={true}
          opportunityByIdData={opportunityByIdData?.data}
        />
      )}
    </div>
  );
};

export default KanbanTable;
