import { useTranslation } from "react-i18next";
import { setModalDeleteNet } from "../../../../redux/modalSlice";
import { useDispatch } from "react-redux";
import "./DeleteNetModal.styles.css";
import { useEffect } from "react";
import { useDeleteNetworkMutation } from "../../../../redux/api/network/networkQuery";
import { deleteNetwork } from "../../../../redux/api/user/userCreatorSlice";

const DeleteNetModal = ({ id, name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [deleteNetworkMutation, { isSuccess: isDeleteSuccess }] =
    useDeleteNetworkMutation();

  const handleDelete = () => {
    deleteNetworkMutation(id);
  };
  const handleClose = () => {
    dispatch(setModalDeleteNet({ modal: false, id: null, name: null }));
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(deleteNetwork({ field: "networks", name: name }));
      dispatch(setModalDeleteNet({ modal: false, id: null, name: null }));
    }
  }, [isDeleteSuccess, dispatch, id, name]);

  return (
    <div className="modalDeleteNet">
      <div className="modalDeleteNet__container">
        <p className="modalDeleteNet__title">
          {t("profile.network_modal_delete.title")}
        </p>
        <div className="modalDeleteNet__subtitle">
          <p>
            {t("profile.network_modal_delete.subtitle.1")}
            <span className="modalDeleteNet__name">{name}</span>
            {t("profile.network_modal_delete.subtitle.2")}
          </p>
          <p>{t("profile.network_modal_delete.subtitle.3")}</p>
        </div>
        <div className="modalDeleteNet__buttons">
          <button onClick={handleDelete}>
            {t("profile.network_modal_delete.btn_delete")}
          </button>
          <button onClick={handleClose}>
            {t("profile.network_modal_delete.btn_cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteNetModal;
