import PropTypes from "prop-types";
import warning from "../../assets/icons/IconWarning.svg";
import "./Auth.styles.css";

const AuthError = ({ message }) => (
  <span className="authFormSpamError">
    <img src={warning} alt="warning" height={16} width={16} />
    {message}
  </span>
);

AuthError.propTypes = {
  message: PropTypes.string.isRequired,
};

export default AuthError;
