import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import creator_default_img from "../../../../assets/images/creator_default_img.jpg";
import warning from "../../../../assets/icons/IconWarning.svg";
import person from "../../../../assets/icons/IconUsername.svg";
import close from "../../../../assets/icons/IconClose.svg";
import edit from "../../../../assets/icons/IconEditWhite.svg";
import "./AddCreators.styles.css";
import { useTranslation } from "react-i18next";
import { useSetImgProfileMutation } from "../../../../redux/api/user/userImgQuery";
import { useAddCreatorMutation } from "../../../../redux/api/user/userQuery";
import { useDispatch } from "react-redux";
import { addCreatorToAgency } from "../../../../redux/api/user/userAgencySlice";
import { useGetTagsMutation } from "../../../../redux/api/tag/tagQuery";

const AddCreators = ({ userType, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
  } = useForm({});

  const [fileImg, setFileImg] = useState(null);
  const [dataForm, setDataForm] = useState(null);
  const [tags, setTags] = useState([]);

  const [getTags, { data: tagsData }] = useGetTagsMutation();

  const [selectedTags, setSelectedTags] = useState([]);

  const [profileImage, setProfileImage] = useState(creator_default_img);

  const [errorImg, setErrorImg] = useState(false);

  const [
    setImgProfile,
    {
      data: profileImgData,
      isSuccess: isProfileImgSuccess,
      isError: isProfileImgError,
    },
  ] = useSetImgProfileMutation();
  const [addCreator, { data: addCreatorData, isSuccess: isAddCreatorSuccess }] =
    useAddCreatorMutation();

  const handleTagClick = (tag) => {
    if (selectedTags.length < 5) {
      setSelectedTags([...selectedTags, tag]);
      setTags(tags.filter((t) => t !== tag));
    }
  };

  const handleSelectedTagClick = (tag) => {
    setTags([...tags, tag]);
    setSelectedTags(selectedTags.filter((t) => t !== tag));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileImg(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmitForm = (data) => {
    const imgFormData = new FormData();
    imgFormData.append("file", fileImg);
    if (fileImg) {
      setImgProfile(imgFormData);
      const formData = {
        ...data,
        tag_names: selectedTags,
      };
      if (!data.description) {
        delete formData.description;
      }
      setDataForm(formData);
    }
    if (!fileImg) {
      const formData = {
        ...data,
        tag_names: selectedTags,
      };
      if (!data.description) {
        delete formData.description;
      }
      addCreator({
        data: formData,
      });
    }
  };
  useEffect(() => {
    if (isProfileImgError) {
      setErrorImg(true);
    }
  }, [isProfileImgError]);

  useEffect(() => {
    if (isProfileImgSuccess) {
      addCreator({
        data: { ...dataForm, profile_img_url: profileImgData.data.url },
      });
    }
  }, [isProfileImgSuccess, dataForm, profileImgData, addCreator]);

  useEffect(() => {
    if (isAddCreatorSuccess && addCreatorData) {
      dispatch(addCreatorToAgency(addCreatorData.data.creator));
      onClose();
    }
  }, [isAddCreatorSuccess, onClose, addCreatorData, dispatch]);

  const usernameValue = watch("username");

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  useEffect(() => {
    getTags();
  }, [userType, getTags]);
  useEffect(() => {
    if (tagsData) {
      const tagsArray = tagsData?.data?.map((tag) => tag.name);
      setTags(tagsArray);
    }
  }, [tagsData]);

  return (
    <div className="overlayCreate">
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="createProfileContainer mediaContainerAddCreator">
          <div className="createProfileForm">
            <p>{t("profile.create.title")}</p>
            <div className="createProfileImgContainer">
              <div
                className={`createProfileImg ${
                  userType === "creator" ? "radiusImgCreator" : "radiusImg"
                }`}
                onClick={() =>
                  document.getElementById("profileImageInput").click()
                }
              >
                <img
                  className={`avatarImg ${
                    userType === "creator" ? "radiusImgCreator" : "radiusImg"
                  }`}
                  src={profileImage}
                  alt="avatar"
                  onError={(e) => {
                    e.target.src = creator_default_img;
                  }}
                />
                <img
                  className={`iconCreate ${
                    userType === "creator" ? "radiusImgCreator" : "radiusImg"
                  }`}
                  src={edit}
                  alt="create"
                />
              </div>
              {errorImg && (
                <span className="formSpamError">
                  <img src={warning} alt="warning" height={16} width={16} />
                  {t(
                    "onboarding.personal_information.profile_img_url.error_format"
                  )}
                </span>
              )}
              <input
                id="profileImageInput"
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>
            <div className="createProfileFormUsername">
              <label>
                {t("onboarding.personal_information.username.label_creator")}
              </label>
              <div className="formUsernameInputContainer">
                <img src={person} alt="iconUN" />
                <input
                  {...register("username", {
                    required: t(
                      "onboarding.personal_information.username.required_error"
                    ),
                    pattern: {
                      value: /^[a-zA-Z0-9]+$/,
                      message: t(
                        "onboarding.personal_information.username.pattern_error"
                      ),
                    },
                    minLength: {
                      value: 3,
                      message: t("landing.sign_up.creator.minlength_error"),
                    },
                  })}
                />
              </div>
              {errors.username && (
                <span className="formSpamError">
                  <img src={warning} alt="warning" height={16} width={16} />
                  {errors.username.message}
                </span>
              )}
            </div>
            <div className="createProfileFormDescription">
              <div className="createProfileFormDescriptionTitle">
                <label>
                  {t("onboarding.personal_information.description.label")}
                </label>
                <span>
                  {t("onboarding.personal_information.description.desc")}
                </span>
              </div>
              <Controller
                name="description"
                control={control}
                rules={{
                  maxLength: {
                    value: 300,
                    message: t(
                      "onboarding.personal_information.description.max_length"
                    ),
                  },
                }}
                render={({ field }) => (
                  <textarea
                    className="createProfileTextarea"
                    {...field}
                  ></textarea>
                )}
              />
              {errors.description && (
                <span className="formSpamError">
                  <img src={warning} alt="warning" height={16} width={16} />
                  {errors.description.message}
                </span>
              )}
            </div>

            <div className="createProfileFormTags">
              <div className="createProfileFormTagsContainer">
                <div className="createProfileFormDescriptionTitle">
                  <label>{t("profile.create.content_you_make")}</label>
                  <span>{t("onboarding.content.tags.max_length")}</span>
                </div>
                <div className="tagsSelectedContainer">
                  {selectedTags.map((tag, index) => (
                    <div className="tag" key={index}>
                      <span>{t(`onboarding.content.tag_list.${tag}`)}</span>
                      <img
                        src={close}
                        alt="close"
                        onClick={() => handleSelectedTagClick(tag)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="createProfileFormTagsMapping">
                {tags.map((tag, index) => (
                  <p
                    className="tag"
                    key={index}
                    onClick={() => handleTagClick(tag)}
                  >
                    {t(`onboarding.content.tag_list.${tag}`)}
                  </p>
                ))}
              </div>
              {selectedTags.length > 5 ? (
                <span className="formSpamError">
                  <img src={warning} alt="warning" height={16} width={16} />
                  {t("onboarding.content.tags.max_length_error")}
                </span>
              ) : null}
            </div>
          </div>
          <div className="createProfileButtons">
            <button
              type="submit"
              className={`${
                selectedTags.length > 5 ||
                errors.username ||
                !isValid ||
                !usernameValue
                  ? "buttonDisabled"
                  : "buttonSave"
              }`}
              disabled={
                selectedTags.length > 5 ||
                errors.username ||
                !isValid ||
                !usernameValue
              }
            >
              {t("profile.create.btn_save")}
            </button>
            <button type="button" className="buttonClose" onClick={onClose}>
              {t("general.btn_close")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCreators;
