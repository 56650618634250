import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "./CreateOpp.styles.css";
import warning from "../../../assets/icons/IconWarning.svg";
import { useTranslation } from "react-i18next";
import {
  useAddOpportunityMutation,
  useUpdateOpportunityMutation,
} from "../../../redux/api/opportunities/opportunitiesQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  editOpportunity,
  pushOpportunity,
} from "../../../redux/api/opportunities/opportunitiesSlice";
import SelectCustomCreators from "../../UI/SelectCustom/SelectCustomCreators";
import SelectCustomStatus from "../../UI/SelectCustom/SelectCustomStatus";
import {
  setModalErrorCreateOpp,
  setModalErrorEditOpp,
} from "../../../redux/modalSlice";

const CreateOpp = ({ onClose, isEdit, opportunityByIdData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    setError,
  } = useForm({
    mode: "onChange",
  });
  const title = watch("title");
  const [errorTitle, setErrorTitle] = useState(false);
  const userLoggedIn = useSelector((state) => state.login.user);
  const agencyRedux = useSelector((state) => state.userAgency.agency);

  const statusOptions = useMemo(
    () => [
      { value: "PENDING", label: t("opportunities.status.pending") },
      { value: "NEGOTIATION", label: t("opportunities.status.negotiation") },
      { value: "FINISHED", label: t("opportunities.status.finished") },
      { value: "CANCELED", label: t("opportunities.status.canceled") },
    ],
    [t]
  );
  const [
    addOpportunity,
    {
      data: opportunityData,
      isSuccess: isOpportunitySuccess,
      isError: isOpportunityError,
      error: opportunityError,
    },
  ] = useAddOpportunityMutation();
  const [
    updateOpportunity,
    {
      data: updateOppData,
      isSuccess: isUpdateOppSuccess,
      isError: isUpdateOppError,
      error: updateOppError,
    },
  ] = useUpdateOpportunityMutation();

  useEffect(() => {
    if (isEdit && opportunityByIdData) {
      setValue("title", opportunityByIdData.title);
      setValue(
        "status",
        statusOptions.find(
          (option) => option.value === opportunityByIdData.status
        )
      );
      if (userLoggedIn?.type === "AGENCY") {
        setValue(
          "creators",
          opportunityByIdData.creators.map((option) => ({
            value: option.username,
            label: option.username,
            data: option,
          }))
        );
      }
      setValue("description", opportunityByIdData.description);
      setValue("advertiser_name", opportunityByIdData.advertiser_name);
      setValue(
        "advertiser_phone_number",
        opportunityByIdData.advertiser_phone_number
      );
      setValue("advertiser_email", opportunityByIdData.advertiser_email);
    }
  }, [isEdit, opportunityByIdData, setValue, statusOptions, userLoggedIn]);

  const onSubmit = (data) => {
    const filteredData = Object.entries(data).reduce((obj, [key, value]) => {
      if (key === "creators" && Array.isArray(value)) {
        obj[key] = value.map((option) => option.value);
      } else if (value !== "" && value !== null && value !== undefined) {
        if (key === "status") {
          obj[key] = value.value;
        } else {
          obj[key] = value;
        }
      }
      return obj;
    }, {});

    if (isEdit) {
      const updatedData = Object.entries(filteredData).reduce(
        (obj, [key, value]) => {
          if (opportunityByIdData[key] !== value) {
            obj[key] = value;
          }
          return obj;
        },
        {}
      );

      updateOpportunity({ id: opportunityByIdData.id, data: updatedData });
    } else {
      addOpportunity(filteredData);
    }
  };

  useEffect(() => {
    if (isOpportunitySuccess) {
      dispatch(pushOpportunity(opportunityData.data));
      onClose();
    }
  }, [isOpportunitySuccess, opportunityData, onClose, dispatch]);

  useEffect(() => {
    if (isOpportunityError) {
      if (
        opportunityError?.data?.error ===
        "Opportunity with title already exists"
      ) {
        setErrorTitle(true);
      } else {
        dispatch(
          setModalErrorCreateOpp({
            modal: true,
            message: opportunityError?.data?.error,
          })
        );
      }
    }
  }, [isOpportunityError, opportunityError, dispatch]);

  useEffect(() => {
    if (isUpdateOppSuccess) {
      dispatch(
        editOpportunity({ id: updateOppData.data.id, data: updateOppData.data })
      );
      onClose();
    }
  }, [isUpdateOppSuccess, updateOppData, dispatch, onClose]);

  useEffect(() => {
    if (isUpdateOppError) {
      if (
        updateOppError?.data?.error === "Opportunity with title already exists"
      ) {
        setErrorTitle(true);
      } else {
        dispatch(
          setModalErrorEditOpp({
            modal: true,
            message: updateOppError?.data?.error,
          })
        );
      }
    }
  }, [isUpdateOppError, updateOppError, dispatch]);

  useEffect(() => {
    if (errorTitle) {
      setError("title", {
        type: "manual",
        message: t("opportunities.create.duplicate_title_error"),
      });
    }
  }, [errorTitle, setError, t]);

  useEffect(() => {
    if (errorTitle) {
      setErrorTitle(false);
    }
  }, [errorTitle]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  return (
    <div className="overlayCreateOpp">
      <div className="createOppContainer oppContainerCreator">
        <form className="createOppForm" onSubmit={handleSubmit(onSubmit)}>
          <p>
            {isEdit
              ? t("opportunities.edit.text")
              : t("opportunities.create.text")}
          </p>
          <div className="createOppFormOppData">
            <div className="createOppFormInputContainer">
              <label>{t("opportunities.create.title_label")}</label>
              <input
                type="text"
                onChange={() => setErrorTitle(false)}
                placeholder={t("opportunities.create.title_placeholder")}
                {...register("title", { required: true })}
              />
              {errors.title && (
                <span className="formSpamError">
                  <img src={warning} alt="warning" height={16} width={16} />
                  {errors.title.message ||
                    t("opportunities.create.title_error")}
                </span>
              )}
            </div>
            <div className="createOppFormSelectContainer">
              <label>{t("opportunities.create.status_label")}</label>
              <Controller
                name="status"
                control={control}
                defaultValue={statusOptions[0]}
                render={({ field }) => (
                  <SelectCustomStatus
                    {...field}
                    options={statusOptions.map((option) => ({
                      value: option.value,
                      label: option.label,
                    }))}
                    onChange={(value) => setValue("status", value)}
                    value={watch("status")}
                    placeholder={t("opportunities.create.status_placeholder")}
                  />
                )}
                rules={{ required: true }}
              />
              {errors.status && (
                <span className="formSpamError">
                  <img src={warning} alt="warning" height={16} width={16} />
                  {t("opportunities.create.status_error")}
                </span>
              )}
            </div>
            <div className="createOppFormTextareaContainer">
              <div className="labelTextareaContainer">
                <label>{t("opportunities.create.description_label")}</label>
                <label>
                  {t("onboarding.personal_information.description.max_length")}
                </label>
              </div>
              <textarea
                className="createOppTextarea"
                placeholder={t("opportunities.create.description_placeholder")}
                {...register("description", { maxLength: 300 })}
              ></textarea>
              {errors.description && (
                <span className="formSpamError">
                  <img src={warning} alt="warning" height={16} width={16} />
                  {t("opportunities.create.description_error")}
                </span>
              )}
            </div>
            {userLoggedIn.type === "AGENCY" && agencyRedux && (
              <div className="createOppFormSelectContainer">
                <label>{t("opportunities.create.creators_label")}</label>
                <Controller
                  name="creators"
                  control={control}
                  render={({ field }) => (
                    <SelectCustomCreators
                      {...field}
                      options={agencyRedux?.creators.map((option) => ({
                        value: option.username,
                        label: option.username,
                        data: option,
                      }))}
                      placeholder={t(
                        "opportunities.create.creators_placeholder"
                      )}
                      onChange={(value) => setValue("creators", value)}
                      value={watch("creators")}
                      inputRef={field.ref}
                    />
                  )}
                />
                {errors.creators && (
                  <span className="formSpamError">
                    <img src={warning} alt="warning" height={16} width={16} />
                    {t("opportunities.create.creators_error")}
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="createOppFormUserData">
            <p className="titleFormUser">
              {t("opportunities.create.advertiser_title")}
            </p>
            <div className="createOppFormInputContainer">
              <label>{t("opportunities.create.advertiser_name_label")}</label>
              <input
                type="text"
                placeholder={t(
                  "opportunities.create.advertiser_name_placeholder"
                )}
                {...register("advertiser_name")}
              />
            </div>
            <div className="createOppFormInputContainer">
              <label>
                {t("opportunities.create.advertiser_phone_number_label")}
              </label>
              <input
                type="text"
                placeholder={t(
                  "opportunities.create.advertiser_phone_number_placeholder"
                )}
                {...register("advertiser_phone_number", {
                  pattern: /^[0-9]+$/,
                  minLength: 10,
                  maxLength: 13,
                })}
              />
              {errors.advertiser_phone_number && (
                <span className="formSpamError">
                  <img src={warning} alt="warning" height={16} width={16} />
                  {t(
                    `opportunities.create.advertiser_phone_number_error.${errors.advertiser_phone_number.type}`
                  )}
                </span>
              )}
            </div>
            <div className="createOppFormInputContainer">
              <label>{t("opportunities.create.email_label")}</label>
              <input
                type="email"
                placeholder={t("opportunities.create.email_placeholder")}
                {...register("advertiser_email", {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                })}
              />
              {errors.advertiser_email && (
                <span className="formSpamError">
                  <img src={warning} alt="warning" height={16} width={16} />
                  {t("opportunities.create.email_error")}
                </span>
              )}
            </div>
          </div>
          <div className="createOppButtons">
            <button
              type="submit"
              className={
                !title ||
                errors.title ||
                errors.status ||
                errors.description ||
                errors.advertiser_phone_number ||
                errors.advertiser_email
                  ? "buttonDisabled"
                  : "buttonSubmit"
              }
              disabled={
                !title ||
                errors.title ||
                errors.status ||
                errors.description ||
                errors.advertiser_phone_number ||
                errors.advertiser_email
              }
            >
              {isEdit
                ? t("opportunities.edit.btn_edit")
                : t("opportunities.create.btn_create")}
            </button>
            <button type="button" className="buttonClose" onClick={onClose}>
              {t("opportunities.create.btn_close")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateOpp;
