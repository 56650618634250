import React from "react";
import HeaderOut from "../../components/UI/HeaderOut/HeaderOut";
import Footer from "../../components/UI/Footer/Footer";
import "./TermsOfService.styles.css";
import { useTranslation } from "react-i18next";

const TermsOfServicePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="termsPageContainer">
        <HeaderOut />
        <div className="termsContainer">
          <div className="termsTitle">
            <p>
              {t("landing.terms_of_service.title_1")}
              <span>{t("landing.terms_of_service.title_2")}</span>
            </p>
          </div>
          <div className="termsContent">
            <p>{t("landing.terms_of_service.paragraph_1")}</p>
            <p>{t("landing.terms_of_service.paragraph_2")}</p>
            <p>{t("landing.terms_of_service.paragraph_3")}</p>

            <p>{t("landing.terms_of_service.paragraph_4")}</p>
            <p>{t("landing.terms_of_service.paragraph_5")}</p>
            <p>{t("landing.terms_of_service.paragraph_6")}</p>
            <p>{t("landing.terms_of_service.paragraph_7")}</p>
            <p>{t("landing.terms_of_service.paragraph_8")}</p>
            <p>{t("landing.terms_of_service.paragraph_9")}</p>
            <p>{t("landing.terms_of_service.paragraph_10")}</p>
            <p>{t("landing.terms_of_service.paragraph_11")}</p>
            <p>{t("landing.terms_of_service.paragraph_12")}</p>
            <p>{t("landing.terms_of_service.paragraph_13")}</p>
            <p>{t("landing.terms_of_service.paragraph_14")}</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfServicePage;
