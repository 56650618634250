import React from "react";
import "./TypeSelection.styles.css";
import TypeSelectionCard from "./TypeSelectionCard";
import TYPES from "./configTypes";
import { useDispatch } from "react-redux";
import { setType } from "../../../redux/api/auth/signup/signupSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const TypeSelection = () => {
  const { t } = useTranslation();
  const typesArray = TYPES();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleType = (type) => {
    dispatch(setType(type));
  };
  const handleHelp = (id) => {
    navigate("/how-it-works", { state: { id } });
  };
  return (
    <div className="typeSelectionContainer">
      <div className="typeSelectionTextContainer">
        <p>{t("landing.sign_up.get_started")}</p>
        <p>{t("landing.sign_up.description")}</p>
      </div>
      <div className="typeSelectionCardsContainer">
        {typesArray.map(({ id, imgSrc, alt, title, description }) => (
          <TypeSelectionCard
            key={id}
            id={id}
            imgSrc={imgSrc}
            alt={alt}
            title={title}
            description={description}
            onClick={handleType}
            onClickHelp={handleHelp}
          />
        ))}
      </div>
    </div>
  );
};

export default TypeSelection;
