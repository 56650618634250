import React from "react";
import PropTypes from "prop-types";
import "./InformationModal.styles.css";
import warning from "../../../../assets/icons/IconWarning.svg";
import check from "../../../../assets/icons/IconCheck.svg";
import email from "../../../../assets/icons/IconEmail.svg";

const InformationModal = ({ data, onClose, onAccept }) => {
  const iconMappings = {
    warning: warning,
    check: check,
    email: email,
  };

  const getIcon = () => {
    const iconSrc = iconMappings[data.icon];
    if (iconSrc) {
      return <img src={iconSrc} alt={data.icon} height={48} width={48} />;
    }
    return null;
  };
  return (
    <div className="overlay">
      <div className="modalContainer">
        {data.icon && (
          <div className="modalIconContainer ">
            <span className="modalIcon">{getIcon()}</span>
          </div>
        )}
        <div className="modalText">
          <p className="modalTitle">{data.title}</p>
          {data.text && <span className="modalSubtitle">{data.text}</span>}
        </div>

        <div className="modalButtonContainer">
          {data.textButtonOk && (
            <button className="modalButtonPrimary" onClick={onAccept}>
              {data.textButtonOk}
            </button>
          )}
          {data.textButtonClose && (
            <button
              className={`${
                data.textButtonOk
                  ? "main-button-secondary"
                  : "modalButtonPrimary "
              }`}
              onClick={onClose}
            >
              {data.textButtonClose}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

InformationModal.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    textButtonOk: PropTypes.string,
    textButtonClose: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InformationModal;
