import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Landing.styles.css";
import landingMainImage from "../../assets/images/landing-main-image.svg";
import leafLeftImage from "../../assets/images/landing-leaf-left.svg";
import leafRightImage from "../../assets/images/landing-leaf-right.svg";
import help from "../../assets/icons/IconHelp.svg";
import arrowTrending from "../../assets/icons/IconArrowtrending.svg";
import { useDispatch } from "react-redux";
import { setType } from "../../redux/api/auth/signup/signupSlice";
import HeaderOut from "../../components/UI/HeaderOut/HeaderOut";
import Footer from "../../components/UI/Footer/Footer";

const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigation = (e) => {
    e.preventDefault();
    navigate("/auth/signup");
    dispatch(setType(null));
  };
  const handleNavigationHIT = (e) => {
    e.preventDefault();
    navigate("/how-it-works");
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="landingPageContainer">
        <HeaderOut />
        <div className="landingContainer">
          <div className="landingInfoContainer">
            <img className="imgLeaf left" src={leafLeftImage} alt="leaf left" />
            <img
              className="imgLeaf right"
              src={leafRightImage}
              alt="leaf right"
            />
            <div className="leftBoxContainer">
              <span className="landingTitle">
                {t("landing.title_1")}
                <span className="primaryColor">{t("landing.title_2")}</span>
                {t("landing.title_3")}
                <span className="gradientColor1">{t("landing.title_4")}</span>
              </span>
              <div className="textContainer">
                <span>{t("landing.paragraph_3")}</span>
                <span>{t("landing.paragraph_4")}</span>
              </div>
              <div className="buttonsContainer">
                <button
                  className="buttonStart"
                  onClick={(e) => handleNavigation(e)}
                >
                  <p>{t("landing.btn_begin")}</p>
                  <img src={arrowTrending} alt="arrowTrending" />
                </button>

                <button
                  className="buttonHowItWorks"
                  onClick={(e) => handleNavigationHIT(e)}
                >
                  <p>{t("landing.btn_how_it_works")}</p>
                  <img src={help} alt="help" />
                </button>
              </div>
            </div>
          </div>
          <div className="landingImageContainer">
            <img
              className="landingImage"
              src={landingMainImage}
              alt="landing"
            />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
