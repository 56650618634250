import React from "react";
import creatorProfileImg from "../../../assets/images/creator_default_img.jpg";
import creatorsIcon from "../../../assets/icons/IconCreators.svg";
import plus from "../../../assets/icons/IconPlus.svg";
import noData from "../../../assets/images/undraw-no-data.svg";
import "../styles/ProfileNetworks.styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAddCreator } from "../../../redux/api/user/userAgencySlice";
import { useNavigate } from "react-router-dom";

const ProfileCreatorsAgency = ({ userType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userLoggedIn = useSelector((state) => state.login.user);
  const loggedInType = userLoggedIn?.type?.toLowerCase() || "";
  const agencyRedux = useSelector((state) => state.userAgency.agency);
  const creators = agencyRedux?.creators || [];

  const handleAddCreator = () => {
    dispatch(setAddCreator(true));
  };

  const handleNavigateCreator = (username) => {
    navigate(`/creator/${username}`);
  };

  const renderEmptyCreators = () => (
    <div className="profileEmptyContent">
      <div className="profileEmptyContentInfo">
        <img src={noData} alt="no-data" />
        {loggedInType === "agency" &&
          userLoggedIn?.username === agencyRedux?.username && (
            <>
              <p>{t("profile.agency.no_data_creators")}</p>

              <button onClick={handleAddCreator} className="profileEmptyButton">
                <img src={plus} alt="add" />
                <p>{t("profile.agency.btn_add_creator")}</p>
              </button>
            </>
          )}
      </div>
    </div>
  );
  const renderAgencyContent = (creators) => (
    <div className="profileNetworksContent">
      {creators.map((creator, creatorIndex) => {
        const maxTagsToShow = 3;
        const tags = creator?.tags || [];
        const tagsToShow = tags.slice(0, maxTagsToShow);
        const remainingTagsCount = tags.length - tagsToShow.length;

        return (
          <div
            key={creatorIndex}
            onClick={() => handleNavigateCreator(creator.username)}
            className="profileNetworksContentInfo"
          >
            <div className="profileNetworksContentInfoAvatar">
              <img
                src={creator.profile_img_url}
                alt="avatar"
                onError={(e) => (e.target.src = creatorProfileImg)}
              />
              <p>{creator.username}</p>
            </div>
            <div className="profileNetworksContentInfoStatsAgency">
              <div className="profileNetworkTags">
                {tagsToShow.map((tag, index) => (
                  <span key={index}>
                    {t(`onboarding.content.tag_list.${tag}`)}
                  </span>
                ))}
                {remainingTagsCount > 0 && <span>+{remainingTagsCount}</span>}
              </div>
            </div>
          </div>
        );
      })}
      {loggedInType === "agency" &&
        userLoggedIn?.username === agencyRedux?.username && (
          <button
            onClick={handleAddCreator}
            className="profileNetworkContentButton"
          >
            <img src={plus} alt="add" />
            <p>{t("profile.agency.btn_add_creator")}</p>
          </button>
        )}
    </div>
  );

  return (
    <div className="profileNetworksContainer">
      <div className="profileNetworksTabs">
        {userType === "agency" && (
          <div className="profileNetworksActiveTab">
            <img src={creatorsIcon} alt="Creadores" />
            <p className="profileNetworksActiveTabCreators">
              {t("profile.agency.our_creators")}
            </p>
          </div>
        )}
      </div>

      {userType === "agency" &&
        (creators.length === 0
          ? renderEmptyCreators("creators")
          : renderAgencyContent(creators))}
    </div>
  );
};

export default ProfileCreatorsAgency;
