import { useDispatch, useSelector } from "react-redux";
import signUpImage from "../../../assets/images/sign-up-person-1.svg";
import InformationModal from "../../../components/UI/ModalMessages/InformationModal/InformationModal";
import SignUpForm from "../../../components/auth/SignUpForm/SignUpForm";
import "./SignUpPage.styles.css";
import { useTranslation } from "react-i18next";
import { setModalError } from "../../../redux/modalSlice";
import TypeSelection from "../../../components/UI/TypeSelection/TypeSelection";
import HeaderOut from "../../../components/UI/HeaderOut/HeaderOut";

function SignUpPage() {
  const modalError = useSelector((state) => state.modal.modalError);
  const type = useSelector((state) => state.signup.data.type);
  const typeLowerCase = type?.toLowerCase();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const modalData = {
    icon: "warning",
    title: t("landing.sign_in.modal_messages.error"),
    text: t("landing.sign_in.modal_messages.error_desc"),
    textButtonClose: t("general.btn_return"),
  };

  const handleClose = () => {
    dispatch(setModalError(false));
  };

  return (
    <>
      {!typeLowerCase ? (
        <>
          <div style={{ backgroundColor: "#eaedf2", overflow: "auto" }}>
            <HeaderOut />
            <TypeSelection />
          </div>
        </>
      ) : (
        <>
          <HeaderOut wType={typeLowerCase} />
          <div className="signUpContainer">
            <div className="signUpFormContainer">
              <SignUpForm type={typeLowerCase} />
            </div>
            <div className="signUpImageContainer">
              <img src={signUpImage} alt="sign-Up" className="signUpImage" />
            </div>
            {modalError && (
              <InformationModal
                data={modalData}
                onClose={handleClose}
                onAccept={null}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default SignUpPage;
