import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  OTPCode: null,
};

export const emailVerificationSlice = createSlice({
  name: "emailVerification",
  initialState,
  reducers: {
    setOTPCode: (state, action) => {
      state.OTPCode = action.payload;
    },
  },
});

export default emailVerificationSlice.reducer;
export const { setOTPCode } = emailVerificationSlice.actions;
