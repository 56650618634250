import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import "./Chat.styles.css";

const Chat = () => {
  const userLoggedIn = useSelector((state) => state.login.user);
  const messages = useSelector(
    (state) => state.conversations.selectedConversationChat
  );

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="messages__container">
      {messages?.map((message, i) => (
        <>
          {message.type === "REGULAR" && (
            <div
              className={`message__container ${
                message.sender_user_id === userLoggedIn.id
                  ? "message__containerOwn"
                  : "message__containerOther"
              }`}
              key={i}
            >
              <p>{message.content}</p>
            </div>
          )}
        </>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default Chat;
