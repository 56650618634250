import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setOTPCode } from "../../../redux/api/auth/emailVerification/emailVerificationSlice";
import "./OTPField.styles.css";

const OTPField = ({ length, errorRes, handleError }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [length]);

  const handleInputChange = (index, e) => {
    handleError(false)
    const { value } = e.target;
    if (isNaN(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) {
      dispatch(setOTPCode(combinedOtp));
    }

    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleInputClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    const firstEmptyIndex = otp.indexOf("");
    if (index > 0 && firstEmptyIndex !== -1 && firstEmptyIndex < index) {
      inputRefs.current[firstEmptyIndex].focus();
    }
  };

  const handleInputKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      if (!newOtp[index] && index > 0) {
        inputRefs.current[index - 1].focus();
        newOtp[index - 1] = "";
        setOtp(newOtp);
      }
    }
  };
  const handleInputOnPaste = (index, e) => {
    e.preventDefault();
    handleError(false)
    const paste = e.clipboardData.getData("text");
    const numericPaste = paste.replace(/\D/g, "");

    if (numericPaste.length > length - index) return;

    const newOtp = [...otp];
    for (let i = 0; i < numericPaste.length; i++) {
      if (index + i < length) {
        newOtp[index + i] = numericPaste[i];
      }
    }
    setOtp(newOtp);

    newOtp.forEach((char, i) => {
      if (inputRefs.current[i]) {
        inputRefs.current[i].value = char;
      }
    });

    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) {
      dispatch(setOTPCode(combinedOtp));
    }
  };
  return (
    <div className="otpContainer">
      {otp.map((value, index) => (
        <input
          key={index}
          ref={(input) => (inputRefs.current[index] = input)}
          type="text"
          value={value}
          onChange={(e) => handleInputChange(index, e)}
          onClick={() => handleInputClick(index)}
          onKeyDown={(e) => handleInputKeyDown(index, e)}
          onPaste={(e) => handleInputOnPaste(index, e)}
          className={`otpInput ${errorRes ? "inputError" : ""}`}
          maxLength={1}
        />
      ))}
    </div>
  );
};

export default OTPField;
