import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalCreateOpp: false,
  modalEditOpp: false,
  opportunities: [],
};

export const opportunitiesSlice = createSlice({
  name: "opportunities",
  initialState,
  reducers: {
    setModalCreateOpp: (state, action) => {
      state.modalCreateOpp = action.payload;
    },
    setModalEditOpp: (state, action) => {
      state.modalEditOpp = action.payload;
    },
    setOpportunitiesData: (state, action) => {
      state.opportunities = action.payload;
    },
    pushOpportunity: (state, action) => {
      state.opportunities.push(action.payload);
    },
    editOpportunity: (state, action) => {
      const { id, data } = action.payload;
      const index = state.opportunities.findIndex((opp) => opp.id === id);
      if (index !== -1) {
        state.opportunities[index] = { ...state.opportunities[index], ...data };
      }
    },
    deleteOpportunity: (state, action) => {
      const id = action.payload;
      state.opportunities = state.opportunities.filter((opp) => opp.id !== id);
    },
  },
});

export default opportunitiesSlice.reducer;
export const {
  setModalCreateOpp,
  setModalEditOpp,
  setOpportunitiesData,
  pushOpportunity,
  editOpportunity,
  deleteOpportunity,
} = opportunitiesSlice.actions;
